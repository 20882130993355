// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledSelectedIcon {
}

export const StyledSelectedIcon = styled.div<IStyledSelectedIcon>`
    width: 50px;
    height: 50px;
    position: absolute;
    top: -20px;
    left: -70px;

    @media (max-width: 800px) {
        width: 0px;
        height: 0px;
    }
`;
// #region module

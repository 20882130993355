// #region module
export const cleaningAnimationFrames = [
    0, 1, 2, 3, 4,
];


export const animations = {
    penguin: {
        idle: 'animate-penguin-idle',
        jump: 'animate-penguin-jump',
        finish: 'animate-penguin-finish',
    },
    paste: {
        jiggle: 'animate-paste-jiggle',
    },
    teeth: {
        whitening: 'animate-whitening',
    },
};
// #endregion module

// #region imports
    // #region libraries
    import React from 'react';
    // #endregion libraries


    // #region external
    import {
        mobileScale,
    } from '../../../../data/constants';
    // #endregion external
// #region imports



// #region module
const ResponsiveSpacer: React.FC<any> = (
    _properties,
) => {
    // #region render
    if (!mobileScale) {
        return (<></>);
    }

    return (
        <div
            style={{
                height: '230px',
            }}
        />
    );
    // #endregion render
}
// #endregion module



// #region exports
export default ResponsiveSpacer;
// #endregion exports

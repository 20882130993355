// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect
    } from 'react';
    // #endregion libraries


    // #region external
    import {
        logo,
    } from '../../data/images';
    // #endregion external


    // #region internal
    import {
        StyledLogo,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface LogoProperties {
}

const Logo: React.FC<LogoProperties> = (
    _properties,
) => {
    // #region properties
    const frameLimit = 5;
    const frameTime = 250;
    // #endregion properties


    // #region state
    const [
        frame,
        setFrame,
    ] = useState(0);
    // #endregion state


    // #region effects
    useEffect(() => {
        const interval = setInterval(() => {
            setFrame(value => {
                if (value >= frameLimit) {
                    return 0;
                }

                return value + 1;
            });
        }, frameTime);

        return () => {
            clearInterval(interval);
        }
    }, []);
    // #endregion effects


    // #region render
    return (
        <StyledLogo>
            <img
                src={logo[frame]}
                alt="logo"
                height={200}
            />
        </StyledLogo>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Logo;
// #endregion exports

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        fonts,
    } from '../../data/constants';
    // #endregion external
// #region imports



// #region module
export const StyledGameContainer = styled.div`
    height: 100%;
    display: grid;
    place-content: center;
`;


export const StyledFontLoad = styled.div`
    font-family: ${fonts.Gamefont};
    font-size: 0rem;
    visibility: hidden;
`;


export const StyledGame = styled.div`
    width: 800px;
    margin: 0 auto;

    @media (max-width: 800px) {
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
    }
`;


export const StyledCommand = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    input {
        background: black;
        color: white;
        outline: none;
        padding: 1.5rem;
        text-align: center;
        border: none;
        font-family: ${fonts.Gamefont};
    }
`;
// #region module

// #region imports
    // #region external
    import {
        mobileScale,
    } from '../../ui';

    import {
        colors,
    } from '../../ui';
    // #endregion external
// #endregion imports



// #region module
export const colorsIndex = {
    0: colors.white,
    1: colors.red,
    2: colors.yellow,
    3: colors.green,
    4: colors.blue,
    5: colors.pink,
};

export const hexBlackColor = 0x000000;


export const bubbles = [
    'bubbles-0',
    'bubbles-1',
    'bubbles-2',
    'bubbles-3',
    'bubbles-4',
];


export const mobileWidthSteps = {
    mobileA: 350,
    mobileB: 400,
    mobileC: 500,
};


export const computeMobileViewType = () => {
    if (
        window.innerWidth < mobileWidthSteps.mobileA
        || window.innerHeight < 500
    ) {
        return 'mobileA';
    }

    if (window.innerWidth < mobileWidthSteps.mobileB) {
        return 'mobileB';
    }

    if (window.innerWidth < mobileWidthSteps.mobileC) {
        return 'mobileC';
    }

    return 'mobileD';
}

export const mobileViewType = computeMobileViewType();


export const mobileBlockSizes = {
    mobileA: 12,
    mobileB: 14,
    mobileC: 16,
    mobileD: 20,
};

export const blockSize = mobileScale
    ? mobileBlockSizes[mobileViewType]
    : 25;
// #endregion module

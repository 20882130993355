// #region imports
    // #region libraries
    import {
        useRef,
        useState,
        useCallback,
        useEffect,
    } from 'react';

    import {
        BrowserRouter as Router,
        Routes,
        Route,
    } from 'react-router-dom';
    // #endregion libraries


    // #region internal
    import music from './assets/sounds/music.wav';

    import {
        localStorer,
    } from './services/objects';

    import {
        GlobalStyle,
        StyledApp,
    } from './services/styles/styled';

    import LandscapeWarning from './components/LandscapeWarning';

    import Home from './containers/Home';
    import Story from './containers/Story';
    import Game from './containers/Game';
    import Highscore from './containers/Highscore';
    import Options from './containers/Options';
    // #endregion internal
// #endregion imports



// #region module
const App = () => {
    // #region references
    const audio = useRef<HTMLAudioElement | undefined>();
    // #endregion references


    // #region state
    const [
        audioPlayable,
        setAudioPlayable,
    ] = useState(false);

    const [
        audioState,
        setAudioState,
    ] = useState('');
    // #endregion state


    // #region handlers
    const playAudio = () => {
        if (!audio.current) {
            return;
        }

        setAudioState('PLAYING');
        audio.current.play();
        audio.current.loop = true;
        audio.current.volume = 0.3;
    }

    const pauseAudio = (
        reset = false,
    ) => {
        if (!audio.current) {
            return;
        }

        setAudioState('PAUSED');
        audio.current.pause();

        if (reset) {
            audio.current.currentTime = 0;
        }
    }

    const handleAudio = useCallback(() => {
        const gameMusic = localStorer.getMusic();

        if (audioState === 'PLAYING' || audioState === 'PAUSED') {
            return;
        }

        if (audioPlayable) {
            if (!gameMusic) {
                if (audio.current && !audio.current.paused) {
                    pauseAudio(true);
                }

                return;
            }

            if (audio.current && audio.current.paused) {
                playAudio();
            }
            return;
        }

        audio.current = new Audio(music);
        if (gameMusic && audio.current.paused) {
            playAudio();
        }

        setAudioPlayable(true);
    }, [
        audioPlayable,
        audioState,
    ]);
    // #endregion handlers


    // #region effects
    useEffect(() => {
        localStorer.onMusic((play) => {
            if (audio.current) {
                if (play) {
                    playAudio();
                } else {
                    pauseAudio(true);
                }
            }
        });
    }, []);

    useEffect(() => {
        const mute = () => {
            const gameMusic = localStorer.getMusic();

            if (!gameMusic) {
                pauseAudio();
                return;
            }

            if (audio.current && audio.current.paused) {
                playAudio();
            } else if (audio.current) {
                pauseAudio();
            }
        }

        const keyBindings = (
            event: KeyboardEvent,
        ) => {
            if (event.code === 'KeyM') {
                mute();
            }
        }

        window.addEventListener('keydown', handleAudio);
        window.addEventListener('keydown', keyBindings);
        window.addEventListener('teethrismute', mute);

        return () => {
            window.removeEventListener('keydown', handleAudio);
            window.removeEventListener('keydown', keyBindings);
            window.removeEventListener('teethrismute', mute);
        }
    }, [
        handleAudio,
        audioState,
    ]);
    // #endregion effects


    // #region render
    return (
        <StyledApp
            onMouseDown={() => {
                handleAudio();
            }}
        >
            <GlobalStyle />

            <LandscapeWarning />

            <Router>
                <Routes>
                    <Route
                        path="/story"
                        element={<Story />}
                    />

                    <Route
                        path="/options"
                        element={<Options />}
                    />

                    <Route
                        path="/highscore"
                        element={<Highscore />}
                    />

                    <Route
                        path="/game"
                        element={<Game />}
                    />

                    <Route
                        path="/"
                        element={<Home />}
                    />
                </Routes>
            </Router>
        </StyledApp>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default App;
// #endregion exports

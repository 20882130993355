// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledTooth {
}

export const StyledTooth = styled.div<IStyledTooth>`
    width: 50px;
    height: 50px;
`;
// #region module

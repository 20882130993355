// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        colors,
        fonts,
    } from '../../data/constants';
    // #endregion external
// #region imports



// #region module
export interface IStyledStartScreen {
}

export const StyledStartScreen = styled.div<IStyledStartScreen>`
    height: 100%;
    text-align: center;
    justify-items: center;
    user-select: none;
    overflow: hidden;

    img {
        pointer-events: none;
    }
`;


export const StyledStartScreenColors = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;
`;


export const StyledText = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 100%;
`;



export const StyledJordan = styled.div`
    font-family: ${fonts.Textfont};
    color: ${colors.grey};

    margin-top: 2rem;
    font-size: 1.5rem;

    a {
        color: white;
    }

    @media (max-width: 800px) {
        margin-top: 0;
    }
`;
// #region module

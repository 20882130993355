// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';

    import {
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import {
        Highscore as IHighscore,
    } from '../../data/interfaces';

    import SelectableItem from '../../components/SelectableItem';
    import Spinner from '../../components/Spinner';

    import {
        StyledButton,
    } from '../../components/styled';

    import {
        useSelected,
    } from '../../services/hooks';

    import {
        getHighscores,
    } from '../../services/server';

    import {
        displayScore,
    } from '../../game/Teethris/logic/utility';
    // #endregion external


    // #region internal
    import {
        StyledHighscore,
        StyledScoresContainer,
        StyledHighscoreItem,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface HighscoreProperties {
    backAction?: () => void;
}

const Highscore: React.FC<HighscoreProperties> = (
    properties,
) => {
    // #region properties
    const {
        backAction,
    } = properties;
    // #endregion properties


    // #region state
    const [
        loading,
        setLoading,
    ] = useState(true);

    const [
        highscores,
        setHighscores,
    ] = useState<IHighscore[]>([]);
    // #endregion state


    // #region effects
    useEffect(() => {
        const load = async () => {
            const highscores = await getHighscores();
            setHighscores(highscores);
            setLoading(false);
        }

        load();
    }, []);

    const navigate = useNavigate();

    const {
        selected,
    } = useSelected({
        initialValue: 0,
        maximumValue: 0,
        action: (
            event,
            selected,
        ) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                switch (selected) {
                    case 0:
                        if (backAction) {
                            backAction();
                            return;
                        }

                        navigate('/story#2');
                        break;
                }
            }
        },
    });
    // #endregion effects


    // #region render
    if (loading) {
        return (
            <Spinner />
        );
    }

    return (
        <StyledHighscore>
            <h1>
                HIGHSCORE
            </h1>

            {/* RAINBOW TOOTH  */}

            {highscores.length === 0 && (
                <StyledScoresContainer>
                    no scores recorded
                </StyledScoresContainer>
            )}

            {highscores.length > 0 && (
                <StyledScoresContainer>
                    {highscores.map((highscore, index) => {
                        const {
                            name,
                            score,
                        } = highscore;

                        const indexValue = (index + 1) + '.';

                        return (
                            <StyledHighscoreItem
                                key={name + '-' + score}
                            >
                                <div>
                                    {indexValue}
                                </div>

                                <div>
                                    {name}
                                </div>

                                <div
                                    style={{
                                        justifySelf: 'right',
                                    }}
                                >
                                    {displayScore(score)}
                                </div>
                            </StyledHighscoreItem>
                        );
                    })}
                </StyledScoresContainer>
            )}

            <div
                style={{
                    width: '80px',
                    margin: '0 auto',
                }}
            >
                <SelectableItem
                    text={(
                        <StyledButton
                            onClick={() => {
                                if (backAction) {
                                    backAction();
                                    return;
                                }

                                navigate('/story#2');
                            }}
                        >
                            BACK
                        </StyledButton>
                    )}
                    selected={selected === 0}
                    iconStyle={{
                        top: '-10px',
                    }}
                />
            </div>
        </StyledHighscore>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Highscore;
// #endregion exports

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledLogo {
}

export const StyledLogo = styled.div<IStyledLogo>`
    display: flex;
    justify-content: left;
    justify-items: left;
    width: 300px;
    margin-top: -7rem;

    img {
        justify-self: left;
    }

    @media (max-width: 800px) {
        padding-top: 7rem;
        transform: scale(0.7) translateX(10px);
    }
`;
// #region module

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledSelectableItem {
}

export const StyledSelectableItem = styled.div<IStyledSelectableItem>`
    position: relative;
`;
// #region module

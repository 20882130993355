// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        cursor,
    } from '../../data/images';
    // #endregion external
// #region imports



// #region module
export interface IStyledSwitch {
}

export const StyledSwitch = styled.div<IStyledSwitch>`
    cursor: url(${cursor}) 16 16, pointer;
    height: 50px;
    width: 50px;
    user-select: none;

    img {
        height: 50px;
        width: 50px;
        pointer-events: none;
    }
`;
// #region module

// #region imports
    // #region external
    import {
        ONE_MINUTE,
    } from '../../general';
    // #endregion external
// #endregion imports



// #region module
export enum Directions {
    Down,
    Left,
    Right,
};


export const CLEANING_TIME = 1_000;
export const DROPPING_TIME = CLEANING_TIME / 2;

export const COMBO_HANGING_TIME = 2_000;

export const COMBO_RAINBOW_CHANCE = 0.03;
export const COMBO_LEVEL_FOAM = 3;


export const WHITE_TEETH_SCORE = 10;
export const COLORED_TEETH_SCORE = 5;



export const DOUBLE_SPEED_TIME = ONE_MINUTE * 2;
export const TRIPLE_SPEED_TIME = ONE_MINUTE * 3;
export const QUADRUPLE_SPEED_TIME = ONE_MINUTE * 4;
export const QUINTUPLE_SPEED_TIME = ONE_MINUTE * 5;

export const speedIncreases = [
    {
        turnSpeed: 2,
        timeout: DOUBLE_SPEED_TIME,
    },
    {
        turnSpeed: 3,
        timeout: TRIPLE_SPEED_TIME,
    },
    {
        turnSpeed: 4,
        timeout: QUADRUPLE_SPEED_TIME,
    },
    {
        turnSpeed: 5,
        timeout: QUINTUPLE_SPEED_TIME,
    },
];
// #endregion module

// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';
    // #endregion libraries


    // #region external
    import {
        teethColorsIndex,
    } from '../../data/constants';

    import {
        teeth,
        cleaning,
    } from '../../data/images';
    // #endregion external


    // #region internal
    import {
        StyledTooth,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface ToothProperties {
    color: string;
    explode?: boolean;
}

const Tooth: React.FC<ToothProperties> = (
    properties,
) => {
    // #region properties
    const {
        color,
        explode,
    } = properties;

    const colorLocation = teethColorsIndex[color];

    const explodeFrames = [0, 1, 2, 3, 0, 0, 0, 0, 1, 2, 3, 4, 5];
    const defaultFrames = [0, 1, 2, 3];
    // #endregion properties


    // #region state
    const [
        exploding,
        setExploding,
    ] = useState(false);

    const [
        frame,
        setFrame,
    ] = useState(0);

    const frames = explode
        ? explodeFrames
        : defaultFrames;
    // #endregion state


    // #region effects
    useEffect(() => {
        if (!exploding) {
            return;
        }

        const interval = setInterval(() => {
            setFrame(value => {
                if (value >= 11) {
                    setExploding(false);
                    return 0;
                }

                return value + 1;
            });
        }, 250);

        return () => {
            clearInterval(interval);
        }
    }, [
        exploding,
    ]);


    useEffect(() => {
        if (exploding) {
            return;
        }

        const interval = setInterval(() => {
            setFrame(value => {
                if (value >= 5) {
                    if (explode) {
                        setExploding(true);
                        return value + 1;
                    }

                    return 0;
                }

                return value + 1;
            });
        }, 250);

        return () => {
            clearInterval(interval);
        }
    }, [
        explode,
        exploding,
    ]);
    // #endregion effects


    // #region render
    if (exploding) {
        return (
            <StyledTooth
                style={{
                    backgroundImage: `url(${cleaning})`,
                    backgroundPosition: `${frames[frame] * 50 * -1}px 0px`,
                }}
            />
        );
    }

    return (
        <StyledTooth
            style={{
                backgroundImage: `url(${teeth})`,
                backgroundPosition: `${frames[frame] * 50 * -1}px ${-1 * 50 * colorLocation}px`,
            }}
        />
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Tooth;
// #endregion exports

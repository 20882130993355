// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect
    } from 'react';
    // #endregion libraries


    // #region external
    import {
        penguin,
        penguinIdle,
    } from '../../data/images';
    // #endregion external
// #region imports



// #region module
export interface PenguinProperties {
    full?: boolean;
}

const Penguin: React.FC<PenguinProperties> = (
    properties,
) => {
    // #region properties
    const {
        full,
    } = properties;
    // #endregion properties


    // #region state
    const [
        frame,
        setFrame,
    ] = useState(0);

    const limit = full
        ? 8
        : 5;

    const frames = full
        ? [0, 1, 2, 3, 3, 2, 1, 0]
        : [0, 1, 2, 1, 0];

    const url = full
        ? penguin
        : penguinIdle;
    // #endregion state


    // #region effects
    useEffect(() => {
        const interval = setInterval(() => {
            setFrame(value => {
                if (value >= limit) {
                    return 0;
                }

                return value + 1;
            });
        }, 250);

        return () => {
            clearInterval(interval);
        }
    }, [
        limit,
    ]);
    // #endregion effects


    // #region render
    return (
        <div
            style={{
                backgroundImage: `url(${url})`,
                width: '100px',
                height: '150px',
                backgroundPosition: `${frames[frame] * -100}px 0px`,
            }}
        />
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Penguin;
// #endregion exports

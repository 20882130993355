// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';

    import {
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region internal
    import {
        StyledStory,
    } from './styled';

    import FirstScreen from './components/FirstScreen';
    import SecondScreen from './components/SecondScreen';
    import ThirdScreen from './components/ThirdScreen';
    import FourthScreen from './components/FourthScreen';
    // #endregion internal
// #region imports



// #region module
export interface StoryProperties {
}

const Story: React.FC<StoryProperties> = (
    properties,
) => {
    // #region state
    const [
        screen,
        setScreen,
    ] = useState(0);
    // #endregion state


    // #region handlers
    const hashNavigation = () => {
        const hash = window.location.hash;

        switch (hash) {
            case '':
            case '#':
            case '#1':
                setScreen(0);
                break;
            case '#2':
                setScreen(1);
                break;
            case '#3':
                setScreen(2);
                break;
            case '#4':
                setScreen(3);
                break;
            default:
                setScreen(0);
        }
    }
    // #endregion handlers


    // #region effects
    const navigate = useNavigate();

    useEffect(() => {
        const keyBinds = (
            event: KeyboardEvent,
        ) => {
            if (event.code === 'Enter'
                || event.code === 'Space'
                || event.code === 'ArrowRight'
            ) {
                const nextScreen = screen + 1;

                if (nextScreen > 3) {
                    navigate('/game');
                } else {
                    setScreen(nextScreen);
                }

                return;
            }
        }

        window.addEventListener('keydown', keyBinds);

        return () => {
            window.removeEventListener('keydown', keyBinds);
        }
    }, [
        navigate,
        screen,
    ]);

    useEffect(() => {
        window.addEventListener('hashchange', hashNavigation);

        return () => {
            window.removeEventListener('hashchange', hashNavigation);
        }
    }, []);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        hashNavigation();
    }, []);

    useEffect(() => {
        if (screen === 0) {
            return;
        }

        switch (screen) {
            case 0:
                window.location.hash = '';
                break;
            case 1:
                window.location.hash = '#2';
                break;
            case 2:
                window.location.hash = '#3';
                break;
            case 3:
                window.location.hash = '#4';
                break;
        }
    }, [
        screen,
    ]);
    // #endregion effects


    // #region render
    let screenRender = (<></>);
    switch (screen) {
        case 0:
            screenRender = (<FirstScreen
                setScreen={setScreen}
            />);
            break;
        case 1:
            screenRender = (<SecondScreen
                setScreen={setScreen}
            />);
            break;
        case 2:
            screenRender = (<ThirdScreen
                setScreen={setScreen}
            />);
            break;
        case 3:
            screenRender = (<FourthScreen
                setScreen={setScreen}
            />);
            break;
    }

    return (
        <StyledStory>
            {screenRender}
        </StyledStory>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Story;
// #endregion exports

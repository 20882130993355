// #region imports
    // #region libraries
    import Phaser from 'phaser';
    // #endregion libraries
// #endregion imports



// #region module
/**
 *
 * https://github.com/gammafp/phaser3-swipe/blob/master/src/main.js
 *
 * @author       Francisco Pereira Alvarado - gammafp <gammafp@gmail.com>
 * @copyright    2018 gammafp.
 */
class PhaserSwipe extends Phaser.Plugins.BasePlugin {
    private directions;
    private scene: any;
    private x: number | undefined;
    private y: number | undefined;


    constructor(
        pluginManager: any,
    ) {
        super(pluginManager);

        this.directions = {
            left: false,
            right: false,
            up: false,
            down: false
        };
    }


    public load(
        scene: any,
    ) {
        const DELTA_LIMIT = 2;

        this.scene = scene;

        this.scene.input.on('pointerdown', (e: any) => {
            this.x = e.x;
            this.y = e.y;
        });

        this.scene.input.on('pointerup', (e: any) => {
            let resetDir = this.resetDirections(this.directions);

            let deltaY = (e.y - this.y!);
            let deltaX = (e.x - this.x!);

            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                if (deltaX > DELTA_LIMIT) {
                    this.sendEvent(resetDir('right'));
                } else if (deltaX < -DELTA_LIMIT) {
                    this.sendEvent(resetDir('left'));
                }
            } else {
                if (deltaY > DELTA_LIMIT) {
                    this.sendEvent(resetDir('down'));
                } else if (deltaY < -DELTA_LIMIT) {
                    this.sendEvent(resetDir('up'));
                }
            }
        });

    }

    public resetDirections(
        dir: any,
    ) {
        return (
            newDir: any,
        ) => {
            let outDir = dir;
            Object.keys(dir).forEach((x) => {
                outDir[x] = x === newDir;
            });
            return outDir;
        };
    }

    public sendEvent(
        dir: any,
    ) {
        this.scene.events.emit('swipe', dir);
    }
}
// #endregion module



// #region exports
export default PhaserSwipe;
// #endregion exports

// #region imports
    // #region libraries
    import styled, {
        createGlobalStyle,
    } from 'styled-components';
    // #endregion libraries
// #endregion imports



// #region module
export const GlobalStyle = createGlobalStyle`
    html, body {
        overscroll-behavior: none;

        width: 100%;
        height: 100%;
        position: fixed;
        overflow: hidden;
    }
`;


export const StyledApp = styled.div`
    height: 100%;
`;
// #endregion module

// #region imports
    // #region external
    import environment from '../../../services/utilities/environment';
    // #endregion external
// #endregion imports



// #region module
export const TEETHRIS_SERVER = environment.production
    // ? 'https://api.teethris.com/highscore'
    // ? 'https://teethris-highscore.herokuapp.com/highscore'
    ? 'https://highscore.teethris.com/highscore'
    : 'http://localhost:3030/highscore';
// #endregion module

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export const StyledGameArea = styled.div`
    display: grid;
    place-content: center;

    padding: 2rem;
    background: hsl(203, 81%, 89%);
    background-repeat: repeat;
    transform: scale(0.8);
    pointer-events: none;

    canvas {
        pointer-events: all !important;
        margin: 0 auto;
    }

    @media (max-width: 800px) {
        box-sizing: border-box;
        transform: scale(1);
        padding: 1rem;
        margin: 0 auto;
    }
`;
// #region module

// #region imports
    // #region libraries
    import styled, {
        createGlobalStyle,
    } from 'styled-components';
    // #region libraries
// #region imports



// #region module
export const DisableScroll = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

export const EnableScroll = createGlobalStyle`
    body {
        overflow: initial;
    }
`;


export interface IStyledLandscapeWarning {
}

export const StyledLandscapeWarning = styled.div<IStyledLandscapeWarning>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background-color: black;
    color: white;

    display: grid;
    place-content: center;
`;


export const StyledLandscapeWarningText = styled.div`
    display: flex;
    gap: 3rem;
    align-items: center;
    line-height: 2;
    text-align: center;
    font-size: 0.7rem;

    img {
        height: 60px;
    }
`;
// #region module

// #region imports
    // #region libraries
    import React from 'react';
    // #endregion libraries


    // #region external
    import SelectedIcon from '../SelectedIcon';
    // #endregion external


    // #region internal
    import {
        StyledSelectableItem,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface SelectableItemProperties {
    // #region required
        // #region values
        text: string | JSX.Element;
        selected: boolean;
        // #endregion values

        // #region methods
        // #endregion methods
    // #endregion required

    // #region optional
        // #region values
        style?: React.CSSProperties;
        iconStyle?: React.CSSProperties;
        // #endregion values

        // #region methods
        atMouseEnter?: () => void;
        // #endregion methods
    // #endregion optional
}

const SelectableItem: React.FC<SelectableItemProperties> = (
    properties,
) => {
    // #region properties
    const {
        // #region required
            // #region values
            text,
            selected,
            // #endregion values

            // #region methods
            // #endregion methods
        // #endregion required

        // #region optional
            // #region values
            style,
            iconStyle,
            // #endregion values

            // #region methods
            atMouseEnter,
            // #endregion methods
        // #endregion optional
    } = properties;
    // #endregion properties


    // #region render
    return (
        <StyledSelectableItem
            onMouseEnter={() => {
                if (atMouseEnter) {
                    atMouseEnter();
                }
            }}
            style={{
                ...style,
            }}
        >
            {selected && (
                <SelectedIcon
                    style={iconStyle}
                />
            )}

            {text}
        </StyledSelectableItem>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default SelectableItem;
// #endregion exports

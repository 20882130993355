// #region imports
    // #region libraries
    import React from 'react';

    import {
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import {
        colors,
    } from '../../data/constants';

    import Logo from '../../components/Logo';

    import StartButton from '../../components/StartButton';

    import {
        useEnter,
    } from '../../services/hooks';
    // #endregion external


    // #region internal
    import {
        StyledStartScreen,
        StyledStartScreenColors,
        StyledText,
        StyledJordan,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface StartScreenProperties {
}

const StartScreen: React.FC<StartScreenProperties> = (
    _properties,
) => {
    // #region properties
    const currentYear = new Date().getFullYear();
    // #endregion properties


    // #region effects
    const navigate = useNavigate();

    useEnter(
        () => {
            navigate('/story');
        },
    );
    // #endregion effects


    // #region render
    return (
        <StyledStartScreen>
            <StyledStartScreenColors>
                <div
                    style={{backgroundColor: colors.backgroundLight}}
                />
                <div
                    style={{backgroundColor: colors.backgroundDark}}
                />
                <div
                    style={{backgroundColor: colors.black}}
                />
            </StyledStartScreenColors>


            <StyledText>
                <Logo />

                <div>
                    <StartButton />

                    <StyledJordan>
                        {currentYear} — powered by <a
                            href="https://www.jordanoralcare.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Jordan<span
                                style={{
                                    color: colors.red,
                                }}
                            >
                                *
                            </span>
                        </a>
                    </StyledJordan>
                </div>
            </StyledText>
        </StyledStartScreen>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default StartScreen;
// #endregion exports

// #region imports
    // #region external
    import {
        Board,
    } from '../../../data/interfaces';

    import Block from '../../Block';
    // #endregion external
// #endregion imports



// #region module
export const getCompleteGroups = (
    board: Board,
) => {
    const groupedIDs: Record<string, string[]> = {};
    const groupedBlocks: number[][][] = [];
    let ids: string[] = [];

    for (let i = 0; i < board.length; i++) {
        const row = board[i];
        for (let j = 0; j < row.length; j++) {
            const grouped = isBlockGrouped(
                i, j,
                board,
                ids,
                groupedIDs,
            );
            if (!grouped) {
                continue;
            }

            const {
                group,
                newIDs,
                groupKind,
                groupIDs,
            } = grouped;

            if (!groupedIDs[groupKind]) {
                groupedIDs[groupKind] = [];
            }
            for (const groupID of groupIDs) {
                groupedIDs[groupKind].push(groupID);
            }

            groupedBlocks.push(group);
            ids = [
                ...newIDs,
            ];
        }
    }

    const filteredGroups = filterGroups(groupedBlocks);

    // console.log('board', board);
    // console.log('groups', filteredGroups);
    // console.log('ids', ids);
    // console.log('groupedIDs', groupedIDs);

    return {
        groups: filteredGroups,
        ids,
    };
}


export const checkGroupedBlocks = (
    blockOne: Block,
    blockTwo: Block,
) => {
    return blockOne.color === blockTwo.color;
}

export const checkBlocksInGroup = (
    blockZero: Block,
    blockOne: Block,
    blockTwo: Block,
) => {
    if (
        blockZero.color === blockOne.color
        && blockOne.color === blockTwo.color
    ) {
        return true;
    }

    return false;
}


export type IndexIncrement = (
    i: number,
    j: number,
    increment: number,
) => {
    i: number,
    j: number,
}

export const checkNeighborsInGroup = (
    i: number,
    j: number,
    currentBlock: Block,
    ids: string[],
    board: Board,
    indexIncrement: IndexIncrement,
    groupKind: string,
) => {
    let inGroup = true;
    let increment = 1;
    const newIDs = [
        ...ids,
    ];
    const groupIndexes = [
        [i, j],
    ];

    while (inGroup) {
        const {
            i: iIndex,
            j: jIndex,
        } = indexIncrement(
            i, j,
            increment,
        );
        const nextRow = board[iIndex];
        if (!nextRow) {
            inGroup = false;
            break;
        }
        const nextBlock = nextRow[jIndex];
        if (!nextBlock) {
            inGroup = false;
            break;
        }

        if (nextBlock.color !== currentBlock.color) {
            inGroup = false;
            break;
        }

        increment +=1;
        groupIndexes.push([iIndex, jIndex]);
    }

    if (groupIndexes.length >= 3) {
        const groupIDs = [];

        for (const indexes of groupIndexes) {
            const [
                row, column,
            ] = indexes;

            const block = board[row][column];
            if (block) {
                newIDs.push(block.id);
                groupIDs.push(block.id);
            }
        }

        return {
            group: groupIndexes,
            newIDs,
            groupKind,
            groupIDs,
        };
    }

    return;
}


export const checkNotAlreadyGrouped = (
    data: any,
    groupedIDs: Record<string, string[]>,
) => {
    const {
        groupKind,
        groupIDs,
    } = data;
    const groupedIDByKind = groupedIDs[groupKind];

    if (!groupedIDByKind) {
        return data;
    }

    for (const groupID of groupIDs) {
        if (groupedIDByKind.includes(groupID)) {
            return;
        }
    }

    return data;
}


export const isBlockGrouped = (
    i: number,
    j: number,
    board: Board,
    ids: string[],
    groupedIDs: Record<string, string[]>,
) => {
    const currentBlock = board[i][j];
    if (!currentBlock) {
        // Empty block.
        return;
    }

    // if (ids.includes(currentBlock.id)) {
    //     // Block already belongs to a group.
    //     return;
    // }


    // #region horizontal
    const horizontalIndexIncrement: IndexIncrement = (
        i, j,
        increment,
    ) => {
        return {
            i,
            j: j + increment,
        };
    }

    const horizontal = checkNeighborsInGroup(
        i, j,
        currentBlock,
        ids,
        board,
        horizontalIndexIncrement,
        'horizontal',
    );
    if (horizontal) {
        return checkNotAlreadyGrouped(
            horizontal,
            groupedIDs,
        );
    }
    // #endregion horizontal


    // #region vertical
    const verticalIndexIncrement: IndexIncrement = (
        i, j,
        increment,
    ) => {
        return {
            i: i + increment,
            j,
        };
    }

    const vertical = checkNeighborsInGroup(
        i, j,
        currentBlock,
        ids,
        board,
        verticalIndexIncrement,
        'vertical',
    );
    if (vertical) {
        return checkNotAlreadyGrouped(
            vertical,
            groupedIDs,
        );
    }
    // #endregion vertical


    // #region diagonal up-down right
    // M - -
    // - M -
    // - - M
    const upDownRightDiagonalIndexIncrement: IndexIncrement = (
        i, j,
        increment,
    ) => {
        return {
            i: i + increment,
            j: j + increment,
        };
    }

    const upDownRightDiagonal = checkNeighborsInGroup(
        i, j,
        currentBlock,
        ids,
        board,
        upDownRightDiagonalIndexIncrement,
        'diagonal-A',
    );
    if (upDownRightDiagonal) {
        return checkNotAlreadyGrouped(
            upDownRightDiagonal,
            groupedIDs,
        );
    }
    // #endregion diagonal up-down


    // #region diagonal up-down left
    // - - M
    // - M -
    // M - -
    const upDownLeftDiagonalIndexIncrement: IndexIncrement = (
        i, j,
        increment,
    ) => {
        return {
            i: i + increment,
            j: j - increment,
        };
    }

    const upDownLeftDiagonal = checkNeighborsInGroup(
        i, j,
        currentBlock,
        ids,
        board,
        upDownLeftDiagonalIndexIncrement,
        'diagonal-B',
    );
    if (upDownLeftDiagonal) {
        return checkNotAlreadyGrouped(
            upDownLeftDiagonal,
            groupedIDs,
        );
    }
    // #endregion diagonal up-down


    // #region diagonal down-up right
    // - - M
    // - M -
    // M - -
    const downUpRightDiagonalIndexIncrement: IndexIncrement = (
        i, j,
        increment,
    ) => {
        return {
            i: i - increment,
            j: j + increment,
        };
    }

    const downUpRightDiagonal = checkNeighborsInGroup(
        i, j,
        currentBlock,
        ids,
        board,
        downUpRightDiagonalIndexIncrement,
        'diagonal-B',
    );
    if (downUpRightDiagonal) {
        return checkNotAlreadyGrouped(
            downUpRightDiagonal,
            groupedIDs,
        );
    }
    // #endregion diagonal down-up right


    // #region diagonal down-up left
    // M - -
    // - M -
    // - - M
    const downUpLeftDiagonalIndexIncrement: IndexIncrement = (
        i, j,
        increment,
    ) => {
        return {
            i: i - increment,
            j: j - increment,
        };
    }

    const downUpLeftDiagonal = checkNeighborsInGroup(
        i, j,
        currentBlock,
        ids,
        board,
        downUpLeftDiagonalIndexIncrement,
        'diagonal-A',
    );
    if (downUpLeftDiagonal) {
        return checkNotAlreadyGrouped(
            downUpLeftDiagonal,
            groupedIDs,
        );
    }
    // #endregion diagonal down-up left

    return;
}


export const filterGroups = (
    groupedBlocks: number[][][],
) => {
    let groupsMap = new Map<string, number[][]>();

    for (const block of groupedBlocks) {
        const sortedBlock = block.sort();

        if (!groupsMap.has(sortedBlock.toString())) {
            groupsMap.set(sortedBlock.toString(), sortedBlock);
        }
    }

    return Array.from(groupsMap.values());
}
// #endregion module

// #region module
export const DEGREES_90 = 1.5708 // in radians
export const degrees = {
    '0': DEGREES_90 * 4,
    '90': DEGREES_90,
    '180': DEGREES_90 * 2,
    '270': DEGREES_90 * 3,
};


export const CORNER_ADJUSTMENT = 3;
// #endregion module

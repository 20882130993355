// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledBrush {
}

export const StyledBrush = styled.div<IStyledBrush>`
    display: flex;
    margin: 0 auto;
    user-select: none;

    img {
        display: block;
        margin: 0 auto;
        pointer-events: none;
        height: 120px;
    }

    @media (max-width: 800px) {
        img {
            height: 80px;
        }
    }
`;
// #region module

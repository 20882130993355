// #region imports
    // #region libraries
    import React from 'react';

    import {
        Link,
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import Penguin from '../../../../components/Penguin';
    import Brush from '../../../../components/Brush';
    import Tooth from '../../../../components/Tooth';
    import SelectableItem from '../../../../components/SelectableItem';

    import {
        StyledButton,
    } from '../../../../components/styled';

    import {
        useSelected,
    } from '../../../../services/hooks';

    import {
        StyledStoryScreen,
        StyledStoryScreenContainer,
        StyledStoryScreenText,
        StyledStoryScreenButtons,
        StyledStoryScreenButtonsGroup,
    } from '../../styled';

    import ResponsiveSpacer from '../ResponsiveSpacer';
    // #endregion external
// #region imports



// #region module
const SecondScreen: React.FC<any> = (
    properties,
) => {
    // #region properties
    const {
        setScreen,
    } = properties;
    // #endregion properties


    // #region effects
    const navigate = useNavigate();

    const {
        selected,
        setSelected,
    } = useSelected({
        initialValue: 1,
        maximumValue: 1,
        action: (
            event,
            selected,
        ) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                switch (selected) {
                    case 0:
                        navigate('/highscore');
                        break;
                    case 1:
                        navigate('/story#3');
                        break;
                }
            }
        },
    });
    // #endregion effects


    // #region render
    return (
        <StyledStoryScreen>
            <StyledStoryScreenContainer>
                <Penguin />

                <div>
                    <Tooth
                        color="white"
                        explode={true}
                    />
                    <Tooth
                        color="white"
                        explode={true}
                    />
                    <Tooth
                        color="white"
                        explode={true}
                    />
                </div>
            </StyledStoryScreenContainer>


            <StyledStoryScreenText
                style={{
                    top: '50px',
                }}
            >
                <p>
                    I will teach you how to
                    <br />
                    keep your <span
                        className="white-text"
                    >
                        smile bright
                    </span>
                    <br />
                    whitening as many
                    <br />
                    teeth as possible...
                </p>
            </StyledStoryScreenText>


            <ResponsiveSpacer />


            <StyledStoryScreenButtons>
                <Brush
                    index={1}
                />

                <StyledStoryScreenButtonsGroup>
                    <SelectableItem
                        text={(
                            <div>
                                <Link
                                    to="/highscore"
                                >
                                    <StyledButton>
                                        HIGHSCORE
                                    </StyledButton>
                                </Link>
                            </div>
                        )}
                        selected={selected === 0}
                        iconStyle={{
                            top: '-10px',
                        }}
                        atMouseEnter={() => {
                            setSelected(0);
                        }}
                    />

                    <SelectableItem
                        text={(
                            <StyledButton
                                onClick={() => setScreen(2)}
                            >
                                NEXT &gt;
                            </StyledButton>
                        )}
                        selected={selected === 1}
                        iconStyle={{
                            top: '-10px',
                        }}
                        atMouseEnter={() => {
                            setSelected(1);
                        }}
                    />
                </StyledStoryScreenButtonsGroup>
            </StyledStoryScreenButtons>
        </StyledStoryScreen>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default SecondScreen;
// #endregion exports

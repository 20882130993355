// #region imports
    // #region libraries
    import React from 'react';
    import ReactDOM from 'react-dom';
    // #endregion libraries


    // #region internal
    import {
        applicationRoot,
    } from './data/constants';

    import './services/styles/index.css';

    import App from './App';
    // #endregion internal
// #endregion imports



// #region module
const application = document.getElementById(applicationRoot);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    application,
);
// #endregion module

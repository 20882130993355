// #region imports
    // #region libraries
    import React, {
        useState,
    } from 'react';
    // #endregion libraries


    // #region external
    import {
        colors,
    } from '../../data/constants/ui';

    import {
        StyledButton,
    } from '../styled';

    import SelectableItem from '../SelectableItem';
    import Tooth from '../Tooth';
    import Paste from '../Paste';

    import Options from '../../containers/Options';
    import Highscore from '../../containers/Highscore';

    import {
        useSelected,
    } from '../../services/hooks';
    // #endregion external


    // #region internal
    import {
        StyledGamePaused,
        StyledPausedIcon,
        StyledPausedAssets,
        StyledPausedText,
        StyledPausedButtons,
        StyledText,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface GamePausedProperties {
}

const GamePaused: React.FC<GamePausedProperties> = (
    properties,
) => {
    // #region state
    const [
        container,
        setContainer,
    ] = useState(<></>);

    const [
        viewing,
        setViewing,
    ] = useState('');
    // #endregion state


    // #region handlers
    const viewOptions = () => {
        setViewing('options');
        setContainer((
            <Options
                backAction={() => {
                    setViewing('');
                    setContainer(<></>);
                }}
            />
        ));
    }

    const viewHighscore = () => {
        setViewing('highscore');
        setContainer((
            <Highscore
                backAction={() => {
                    setViewing('');
                    setContainer(<></>);
                }}
            />
        ));
    }
    // #endregion handlers


    // #region effects
    const {
        selected,
        setSelected,
    } = useSelected({
        initialValue: 0,
        maximumValue: 1,
        action: (
            event,
            selected,
        ) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                switch (selected) {
                    case 0:
                        viewOptions();
                        break;
                    case 1:
                        viewHighscore();
                        break;
                }
            }
        },
    });
    // #endregion effects


    // #region render
    return (
        <StyledGamePaused
            data-name="UNPAUSE-GAME"
        >
            {viewing && (
                <>
                    {container}
                </>
            )}

            {!viewing && (
                <>
                    <StyledPausedIcon>
                        <StyledPausedAssets>
                            <Tooth
                                color={'white'}
                            />
                            <Tooth
                                color={'rainbow'}
                            />
                            <Paste />
                        </StyledPausedAssets>

                        <StyledPausedText>
                            <span style={{ color: colors.pink }}>P</span>
                            <span style={{ color: colors.red }}>A</span>
                            <span style={{ color: colors.yellow }}>U</span>
                            <span style={{ color: colors.green }}>S</span>
                            <span style={{ color: colors.blue }}>E</span>
                            <span style={{ color: colors.white }}>D</span>
                        </StyledPausedText>
                    </StyledPausedIcon>

                    <div
                        style={{
                            pointerEvents: 'none',
                        }}
                    >
                        <StyledPausedButtons>
                            <SelectableItem
                                text={(
                                    <StyledButton
                                        onClick={() => {
                                            viewOptions();
                                        }}
                                        style={{
                                            pointerEvents: 'all',
                                        }}
                                    >
                                        OPTIONS
                                    </StyledButton>
                                )}
                                selected={selected === 0}
                                style={{
                                    pointerEvents: 'all',
                                }}
                                iconStyle={{
                                    top: '-10px',
                                }}
                                atMouseEnter={() => {
                                    setSelected(0);
                                }}
                            />

                            <SelectableItem
                                text={(
                                    <StyledButton
                                        onClick={() => {
                                            viewHighscore();
                                        }}
                                        style={{
                                            pointerEvents: 'all',
                                        }}
                                    >
                                        HIGHSCORE
                                    </StyledButton>
                                )}
                                selected={selected === 1}
                                style={{
                                    pointerEvents: 'all',
                                }}
                                iconStyle={{
                                    top: '-10px',
                                }}
                                atMouseEnter={() => {
                                    setSelected(1);
                                }}
                            />
                        </StyledPausedButtons>

                        <StyledText>
                            use arrow keys or swipe
                            <br />
                            to move and flip colors
                        </StyledText>
                    </div>
                </>
            )}
        </StyledGamePaused>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default GamePaused;
// #endregion exports

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledHighscore {
}

export const StyledHighscore = styled.div<IStyledHighscore>`
    display: grid;
    height: 100%;
    text-align: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;

    h1 {
        color: white;
    }

    @media (max-width: 800px) {
        h1 {
            font-size: 1.3rem;
        }

        font-size: 0.8rem;
    }
`;


export const StyledScoreType = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 1rem;
    margin-bottom: 2rem;
`;


export const StyledScoresContainer = styled.div`
    margin: 6rem 0;

    @media (max-width: 900px) {
        margin: 2rem 0;
    }
`;

export const StyledHighscoreItem = styled.div`
    display: grid;
    grid-template-columns: 50px 200px 100px;
    text-align: left;
    margin-bottom: 1rem;

    @media (max-width: 900px) {
        font-size: 0.8rem;
        grid-template-columns: 50px 150px 100px;
    }
`;
// #region module

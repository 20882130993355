// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledTextSmile {
}

export const StyledTextSmile = styled.div<IStyledTextSmile>`
    @media (max-width: 800px) {
        margin-top: 1rem;
    }
`;
// #region module

// #region imports
    // #region libraries
    import React from 'react';

    import {
        Link,
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import Penguin from '../../../../components/Penguin';
    import Brush from '../../../../components/Brush';
    import Tooth from '../../../../components/Tooth';
    import SelectableItem from '../../../../components/SelectableItem';

    import {
        StyledButton,
    } from '../../../../components/styled';

    import {
        useSelected,
    } from '../../../../services/hooks';

    import {
        StyledStoryScreen,
        StyledStoryScreenContainer,
        StyledStoryScreenText,
        StyledStoryScreenButtons,
        StyledStoryScreenButtonsGroup,
    } from '../../styled';

    import ResponsiveSpacer from '../ResponsiveSpacer';
    // #endregion external


    // #region internal
    import {
        StyledTextSmile,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
const FirstScreen: React.FC<any> = (
    properties,
) => {
    // #region properties
    const {
        setScreen,
    } = properties;
    // #endregion properties


    // #region effects
    const navigate = useNavigate();

    const {
        selected,
        setSelected,
    } = useSelected({
        initialValue: 1,
        maximumValue: 1,
        action: (
            event,
            selected,
        ) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                switch (selected) {
                    case 0:
                        navigate('/options');
                        break;
                    case 1:
                        navigate('/story#2');
                        break;
                }
            }
        },
    });
    // #endregion effects


    // #region render
    return (
        <StyledStoryScreen>
            <StyledStoryScreenContainer>
                <Penguin />

                <Tooth
                    color="white"
                />
            </StyledStoryScreenContainer>


            <StyledStoryScreenText>
                <p>
                    Hello, I'm the <span
                        className="blue-text"
                    >
                        Jordan penguin
                    </span>!

                    <br />

                    I'm sure you heard about me.
                </p>

                <StyledTextSmile>
                    I help you with your smile :)
                </StyledTextSmile>
            </StyledStoryScreenText>


            <ResponsiveSpacer />


            <StyledStoryScreenButtons>
                <Brush
                    index={0}
                />

                <StyledStoryScreenButtonsGroup>
                    <SelectableItem
                        text={(
                            <div>
                                <Link
                                    to="/options"
                                    onMouseEnter={() => {
                                        setSelected(0);
                                    }}
                                >
                                    <StyledButton>
                                        OPTIONS
                                    </StyledButton>
                                </Link>
                            </div>
                        )}
                        selected={selected === 0}
                        iconStyle={{
                            top: '-10px',
                        }}
                    />

                    <SelectableItem
                        text={(
                            <StyledButton
                                onClick={() => setScreen(1)}
                            >
                                NEXT &gt;
                            </StyledButton>
                        )}
                        selected={selected === 1}
                        iconStyle={{
                            top: '-10px',
                        }}
                        atMouseEnter={() => {
                            setSelected(1);
                        }}
                    />
                </StyledStoryScreenButtonsGroup>
            </StyledStoryScreenButtons>
        </StyledStoryScreen>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default FirstScreen;
// #endregion exports

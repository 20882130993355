// #region imports
    // #region libraries
    import React from 'react';
    // #endregion libraries


    // #region external
    import {
        teeth,
    } from '../../data/images';
    // #endregion external


    // #region internal
    import {
        StyledSelectedIcon,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface SelectedIconProperties {
    style?: React.CSSProperties;
}

const SelectedIcon: React.FC<SelectedIconProperties> = (
    properties,
) => {
    // #region properties
    const {
        style,
    } = properties;
    // #endregion properties


    // #region render
    return (
        <StyledSelectedIcon
            style={{
                backgroundImage: `url(${teeth})`,
                backgroundPosition: `0px 0px`,
                ...style,
            }}
        />
    );
    // #endregion render
}
// #endregion module



// #region exports
export default SelectedIcon;
// #endregion exports

// #region module
const environment = {
    // production: true,
    // development: false,
    production: process.env.NODE_ENV === 'production',
    development: process.env.NODE_ENV !== 'production',
};
// #endregion module



// #region exports
export default environment;
// #endregion exports

// #region imports
    // #region libraries
    import Phaser from 'phaser';

    import {
        uuid,
    } from '@plurid/plurid-functions';
    // #endregion libraries


    // #region external
    import Game from '../Teethris';

    import {
        BLOCK_WIDTH,
        BANNER_HEIGHT,
        LINING_WIDTH,
        NUM_COLORS,

        colorFrames,
        colorsNames,

        blockShowLabels,

        blockSize,
        imagesCoordinates,
    } from '../../data/constants/game';
    // #endregion external
// #endregion imports



// #region module
class Block {
    public id: string = uuid.generate();
    public x: number | null = 0;
    public y: number | null = 0;
    public color: number | null = null;
    public sprite: Phaser.GameObjects.Sprite | null = null;
    public label: Phaser.GameObjects.Text | null = null;
    public tween: Phaser.Tweens.Tween | null = null;


    private Teethris: Game | null;
    private scaleFactor = 1;
    private forceHide = false;


    constructor(
        Teethris: Game | null = null,
        color?: number,
    ) {
        this.Teethris = Teethris;
        this.color = color ?? Math.floor(Math.random() * NUM_COLORS);

        if (Teethris && Teethris.viewType === 'mobile') {
            this.scaleFactor = (blockSize / BLOCK_WIDTH) * 2;
        }
    }


    private getLocationOffsets() {
        const verticalOffset = -1 * blockSize * 4;

        if (this.Teethris?.viewType === 'desktop') {
            const xOffset = LINING_WIDTH;
            const yOffset = BANNER_HEIGHT + verticalOffset;

            return {
                xOffset,
                yOffset,
            };
        }

        const mobileOffset = blockSize + 5;
        const frameBackground = imagesCoordinates.mobile['game-background'];
        const xOffset = frameBackground.offsetX * blockSize + mobileOffset;
        const yOffset = frameBackground.offsetY * blockSize + mobileOffset + verticalOffset;

        return {
            xOffset,
            yOffset,
        };
    }

    private getSpriteLocation() {
        if (
            typeof this.x !== 'number'
            || typeof this.y !== 'number'
        ) {
            return;
        }

        const {
            xOffset,
            yOffset,
        } = this.getLocationOffsets();

        const spriteX = this.x * (blockSize * 2) + xOffset;
        const spriteY = this.y * (blockSize * 2) + yOffset;

        return {
            x: spriteX,
            y: spriteY,
        };
    }

    private destroySprite() {
        this.sprite?.destroy();
        this.sprite = null;

        if (blockShowLabels) {
            this.label?.destroy();
            this.label = null;
        }
    }

    private hideAboveFold() {
        if (this.forceHide) {
            return;
        }

        if (this.y === 0 || this.y === 1) {
            this.setVisible(false);
        } else {
            this.setVisible(true);
        }
    }


    public makeBlock(
        x: number,
        y: number,
        color?: number,
        asset = 'teeth',
        animate: boolean = true,
    ) {
        this.x = x;
        this.y = y;
        this.color = color ?? this.color;
        if (typeof this.color !== 'number') {
            return;
        }

        const spriteLocation = this.getSpriteLocation();
        if (!spriteLocation) {
            return;
        }

        if (this.Teethris) {
            this.sprite = this.Teethris.add.sprite(
                spriteLocation.x,
                spriteLocation.y,
                asset,
                (colorFrames as any)[this.color][0],
            );
            if (this.Teethris.viewType === 'mobile') {
                this.sprite.setScale(
                    this.scaleFactor,
                    this.scaleFactor,
                );
            }
            this.hideAboveFold();

            if (animate) {
                const animationName = `animate-${(colorsNames as any)[this.color]}`;
                this.sprite.play(animationName);
            }

            if (blockShowLabels) {
                this.label = this.Teethris.add.text(
                    spriteLocation.x - 10,
                    spriteLocation.y - 20,
                    this.color + '',
                    {
                        color: 'white',
                        fontSize: '40px',
                    },
                );
            }
        }
    }

    public moveBlock(
        x: number,
        y: number,
    ) {
        this.x = x;
        this.y = y;

        const spriteLocation = this.getSpriteLocation();
        if (!spriteLocation) {
            return;
        }

        const duration = 55;
        const repeat = 0;
        const ease = Phaser.Math.Easing.Quadratic.In;
        const autoStart = false;
        const delay = 0;
        const yoyo = false;

        if (this.Teethris) {
            this.tween = this.Teethris.tweens.add({
                targets: this.sprite,
                x: spriteLocation.x,
                y: spriteLocation.y,
                duration,
                ease,
                autoStart,
                delay,
                repeat,
                yoyo,
            });

            if (blockShowLabels) {
                if (this.label) {
                    this.label.setPosition(
                        spriteLocation.x - 10,
                        spriteLocation.y - 20,
                    );
                }
            }

            setTimeout(() => {
                this.hideAboveFold();
            }, duration);
        }
    }

    public clean() {
        if (
            this.sprite
            && typeof this.color === 'number'
        ) {
            const animationName = `animate-${(colorsNames as any)[this.color]}-cleaning`;
            this.sprite.play(animationName);
        }

        setTimeout(() => {
            this.destroy();
        }, 600);
    };

    public destroy() {
        this.x = null;
        this.y = null;
        this.color = null;
        this.tween = null;
        this.destroySprite();
    }

    public recolorBlock(
        color: number,
    ) {
        this.color = color;
        this.destroySprite();

        if (
            typeof this.x !== 'number'
            || typeof this.y !== 'number'
        ) {
            return;
        }

        this.makeBlock(
            this.x,
            this.y,
        );
    }

    public whitenBlock() {
        if (
            this.sprite
        ) {
            this.sprite.play('animate-whitening');
        }
    }


    public setVisible(
        value: boolean,
    ) {
        if (!this.sprite) {
            return;
        }

        this.sprite.setVisible(value);
    }

    public setForceHide() {
        this.forceHide = true;
        this.setVisible(false);
    }
}
// #endregion module



// #region exports
export default Block;
// #endregion exports

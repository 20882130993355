// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledPaste {
}

export const StyledPaste = styled.div<IStyledPaste>`
    width: 50px;
    height: 150px;
`;
// #region module

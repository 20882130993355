// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        colors,
        fonts,
    } from '../../data/constants';
    // #endregion external
// #region imports



// #region module
export interface IStyledStory {
}

export const StyledStory = styled.div<IStyledStory>`
    display: grid;
    place-content: center;
    height: 100%;
    justify-items: center;
    text-align: left;
    font-family: ${fonts.Textfont};
    font-size: 2rem;
    color: ${colors.grey};
`;


export const StyledStoryScreen = styled.div`
    width: 520px;
    position: relative;

    .blue-text {
        color: ${colors.blue};
    }

    .white-text {
        color: ${colors.white};
    }

    @media (max-width: 800px) {
        width: 100%;
        display: grid;
        place-content: center;
        justify-items: center;
        overflow: hidden;

        position: initial;
    }
`;



export const StyledStoryScreenContainer = styled.div`
    display: flex;
    align-items: flex-end;
    height: 200px;

    @media (max-width: 800px) {
        justify-content: center;
        transform: scale(0.6);
        height: 150px;
    }
`;


export const StyledStoryScreenText = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 350px;
    font-size: 1.8rem;

    p {
        margin-bottom: 3rem;
    }

    @media (max-width: 800px) {
        position: initial;
        width: initial;
        text-align: center;
        font-size: 1.3rem;

        p {
            margin: 0;
        }
    }
`;


export const StyledStoryScreenButtons = styled.div`
    margin-top: 7rem;

    @media (max-width: 800px) {
        margin: 0;
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        right: 0;
    }
`;


export interface IStyledStoryScreenButtonsGroup {
    lastScreen?: boolean;
}

export const StyledStoryScreenButtonsGroup = styled.div<IStyledStoryScreenButtonsGroup>`
    margin: 0 auto;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 40px;
    justify-items: left;
    width: 200px;

    @media (max-width: 800px) {
        justify-items: ${
            ({
                lastScreen,
            }: IStyledStoryScreenButtonsGroup) => lastScreen ? 'left' : 'center'
        };
    }
`;
// #region module

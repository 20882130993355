// #region module
export enum Colors {
    White,
    Red,
    Yellow,
    Green,
    Blue,
    Pink,
    Rainbow,
};

export const colorFrames = {
    // white
    0: [0, 1, 2, 3],

    // red
    1: [5, 6, 7, 8],

    // yellow
    2: [10, 11, 12, 13],

    // green
    3: [15, 16, 17, 18],

    // blue
    4: [20, 21, 22, 23],

    // pink
    5: [25, 26, 27, 28],

    // rainbow
    6: [30, 31, 32, 33, 34],
};


export const teethColorsIndex: Record<string, number> = {
    white: 0,
    red: 1,
    orange: 2,
    green: 3,
    blue: 4,
    purple: 5,
    rainbow: 6,
};

export const colorsNames = {
    0: 'white',
    1: 'red',
    2: 'yellow',
    3: 'green',
    4: 'blue',
    5: 'pink',
    6: 'rainbow',
};
// #endregion module

// #region imports
    // #region libraries
    import React from 'react';

    import {
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import Penguin from '../../../../components/Penguin';
    import Brush from '../../../../components/Brush';
    import Paste from '../../../../components/Paste';
    import SelectableItem from '../../../../components/SelectableItem';

    import {
        StyledButton,
    } from '../../../../components/styled';

    import {
        useSelected,
    } from '../../../../services/hooks';

    import {
        StyledStoryScreen,
        StyledStoryScreenContainer,
        StyledStoryScreenText,
        StyledStoryScreenButtons,
        StyledStoryScreenButtonsGroup,
    } from '../../styled';

    import ResponsiveSpacer from '../ResponsiveSpacer';
    // #endregion external
// #region imports



// #region module
const ThirdScreen: React.FC<any> = (
    properties,
) => {
    // #region properties
    const {
        setScreen,
    } = properties;
    // #endregion properties


    // #region effects
    const navigate = useNavigate();

    const {
        selected,
        setSelected,
    } = useSelected({
        initialValue: 1,
        maximumValue: 1,
        action: (
            event,
            selected,
        ) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                switch (selected) {
                    case 0:
                        navigate('/story#2');
                        break;
                    case 1:
                        navigate('/story#4');
                        break;
                }
            }
        },
    });
    // #endregion effects


    // #region render
    return (
        <StyledStoryScreen>
            <StyledStoryScreenContainer>
                <Penguin />

                <div
                    style={{
                        display: 'flex',
                        gap: '25px',
                    }}
                >
                    <Paste />
                </div>
            </StyledStoryScreenContainer>

            <StyledStoryScreenText
                style={{
                    top: '50px',
                }}
            >
                <p>
                    A <span
                        className="white-text"
                    >
                        toothpaste friend
                    </span> will
                    <br />
                    make all your <span
                        className="white-text"
                    >
                        teeth white
                    </span>,
                    <br />
                    helping you score more points.
                </p>
            </StyledStoryScreenText>


            <ResponsiveSpacer />


            <StyledStoryScreenButtons>
                <Brush
                    index={2}
                />

                <StyledStoryScreenButtonsGroup>
                    <SelectableItem
                        text={(
                            <StyledButton
                                onClick={() => setScreen(1)}
                            >
                                &lt; BACK
                            </StyledButton>
                        )}
                        selected={selected === 0}
                        iconStyle={{
                            top: '-10px',
                        }}
                        atMouseEnter={() => {
                            setSelected(0);
                        }}
                    />

                    <SelectableItem
                        text={(
                            <StyledButton
                                onClick={() => setScreen(3)}
                            >
                                NEXT &gt;
                            </StyledButton>
                        )}
                        selected={selected === 1}
                        iconStyle={{
                            top: '-10px',
                        }}
                        atMouseEnter={() => {
                            setSelected(1);
                        }}
                    />
                </StyledStoryScreenButtonsGroup>
            </StyledStoryScreenButtons>
        </StyledStoryScreen>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default ThirdScreen;
// #endregion exports

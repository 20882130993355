// #region imports
    // #region libraries
    import {
        mathematics,
    } from '@plurid/plurid-functions';
    // #endregion libraries


    // #region external
    import Game from '../Teethris';
    import Block from '../Block';

    import {
        Colors,

        NUM_BLOCKS_IN_SHAPE,

        BLOCK_WIDTH,

        BOARD_HEIGHT,
        BOARD_WIDTH,

        shapeCenters,
        shapeBlockPosition,

        availableColorsList,

        colorFrames,
        colorsNames,

        Directions,

        spritesheetsNames,
        animations,

        audioNames,

        blockSize,

        imagesCoordinates,
    } from '../../data/constants/game';
    // #endregion external
// #endregion imports



// #region module
class Shape {
    // #region properties
    public isTweening = false;
    public isRainbow = false;
    public isPaste = false;

    private availableColors = [
        ...availableColorsList,
    ];

    private centerX: number | null = 0;
    private centerY: number | null = 0;

    private blocks: Block[] = [];
    private previews: Phaser.GameObjects.Sprite[] = [];
    private format: number[] | null = null;

    private tweenCounter = 0;
    private tempCounter = 0;

    private Teethris: Game;
    private scaleFactor = 1;

    private paste: Phaser.GameObjects.Sprite | null = null;
    // #endregion properties


    constructor(
        Teethris: Game,
        format: number[] | null = null,
    ) {
        this.Teethris = Teethris;
        this.format = format;

        if (Teethris && Teethris.viewType === 'mobile') {
            this.scaleFactor = (blockSize / BLOCK_WIDTH) * 2;
        }
    }


    public randomizeShape() {
        // this.type = Math.floor(Math.random() * NUM_SHAPE_TYPES);
        // this.orientation = Math.floor(Math.random() * NUM_ORIENTATIONS);
        // this.color = Math.floor(Math.random() * NUM_COLORS);

        this.initBlocks();
    }

    public preview() {
        const {
            x,
            y,
        } = this.getNextShapeCoordinates();

        for (const [index, block] of this.blocks.entries()) {
            if (typeof block.color !== 'number') {
                continue;
            }

            this.previews[index] = this.Teethris.add.sprite(
                x,
                y + (blockSize * 2) * index,
                'teeth',
                (colorFrames as any)[block.color][0],
            );
            if (this.Teethris.viewType === 'mobile') {
                this.previews[index].setScale(
                    this.scaleFactor,
                    this.scaleFactor,
                );
            }
        }
    }

    public clearPreview() {
        for (const preview of this.previews) {
            if (preview) {
                preview.destroy();
            }
        }

        this.previews = [];
    }

    public cleaningPreview() {
        this.clearPreview();

        const {
            x,
            y,
        } = this.getNextShapeCoordinates();

        for (const [index, block] of this.blocks.entries()) {
            if (typeof block.color !== 'number') {
                continue;
            }

            this.previews[index] = this.Teethris.add.sprite(
                x,
                y + (blockSize * 2) * index,
                'cleaning',
                0,
            );
            if (this.Teethris.viewType === 'mobile') {
                this.previews[index].setDisplaySize(blockSize * 2, blockSize * 2);
            }
        }
    }

    public rainbowPreview() {
        this.clearPreview();

        const {
            x,
            y,
        } = this.getNextShapeCoordinates();

        for (const [index, block] of this.blocks.entries()) {
            if (typeof block.color !== 'number') {
                continue;
            }

            this.previews[index] = this.Teethris.add.sprite(
                x,
                y + (blockSize * 2) * index,
                'teeth',
                (colorFrames as any)[6][0],
            );
            if (this.Teethris.viewType === 'mobile') {
                this.previews[index].setDisplaySize(blockSize * 2, blockSize * 2);
            }

            this.previews[index].play(
                `animate-${(colorsNames as any)[6]}`,
            );
        }
    }

    public activate() {
        this.centerX = shapeCenters.x;
        this.centerY = shapeCenters.y;

        for (let i = 0; i < this.blocks.length; i++) {
            const newX = this.centerX + shapeBlockPosition[i].x;
            const newY = this.centerY + shapeBlockPosition[i].y;
            this.blocks[i].makeBlock(
                newX,
                newY,
                this.isRainbow ? Colors.Rainbow : undefined,
            );
        }

        if (this.isPaste) {
            this.drawPaste();
        }
    }

    public clearActive() {
        this.centerX = null;
        this.centerY = null;

        this.blocks = [];
    };


    public updateTween() {
        if (this.tweenCounter > 10) {
            this.isTweening = false;
            this.tweenCounter = 0;
        }

        this.tweenCounter++;
    }


    public canMoveShape(
        direction: Directions,
    ) {
        let newX: number | null;
        let newY: number | null;

        for (let i = 0; i < this.blocks.length; i++) {
            switch(direction) {
                case Directions.Down:
                    newX = this.blocks[i].x || 0;
                    newY = (this.blocks[i].y || 0) + 1;
                    break;
                case Directions.Left:
                    newX = (this.blocks[i].x || 0) - 1;
                    newY = (this.blocks[i].y || 0);
                    break;
                case Directions.Right:
                    newX = (this.blocks[i].x || 0) + 1;
                    newY = (this.blocks[i].y || 0);
                    break;
            }

            if (
                !this.isOnBoard(newX, newY)
                || this.isOccupied(newX, newY)
            ) {
                return false;
            }
        }

        return true;
    }

    public moveShape(
        direction: Directions,
    ) {
        if (
            typeof this.centerX !== 'number'
            || typeof this.centerY !== 'number'
        ) {
            return;
        }

        if (!this.canMoveShape(direction)) {
            throw new Error(`Cannot move active shape in direction: ${direction}`);
        }

        let newX: number | null;
        let newY: number | null;

        // Move the Shape's blocks
        for (let i = 0; i < this.blocks.length; i++) {
            switch(direction) {
                case Directions.Down:
                    newX = this.blocks[i].x || 0;
                    newY = (this.blocks[i].y || 0) + 1;
                    break;
                case Directions.Left:
                    newX = (this.blocks[i].x || 0) - 1;
                    newY = (this.blocks[i].y || 0);
                    break;
                case Directions.Right:
                    newX = (this.blocks[i].x || 0) + 1;
                    newY = (this.blocks[i].y || 0);
                    break;
            }

            this.blocks[i].moveBlock(newX, newY);


            if (i === 0) {
                if (this.isPaste) {
                    const {
                        xOffset,
                        yOffset,
                    } = this.getLocationOffsets();

                    // const x = newX * 50 + 25;
                    // const y = newY * 50 - 75;
                    const x = xOffset + newX * blockSize * 2 + blockSize;
                    const y = yOffset + newY * blockSize * 2 - blockSize * 3;

                    if (this.paste) {
                        this.paste.setPosition(
                            x, y,
                        );
                    }
                }
            }
        }

        // Update the Shape's center
        switch(direction) {
            case Directions.Down:
                this.centerX += 0;
                this.centerY += 1;
                break;
            case Directions.Left:
                this.centerX += -1;
                this.centerY += 0;
                break;
            case Directions.Right:
                this.centerX += 1;
                this.centerY += 0;
                break;
        }
    }


    public placeShapeInBoard() {
        for (let i = 0; i < this.blocks.length; i++) {
            const block = this.blocks[i];

            if (
                typeof block.x !== 'number'
                || typeof block.y !== 'number'
            ) {
                continue;
            }

            this.Teethris.board[block.y][block.x] = this.blocks[i];
        }

        this.Teethris.playSound(
            audioNames.pieceTouch,
        );
    }

    public isOnBoard(
        x: number,
        y: number,
    ) {
        if (
            x >= 0
            && y >= 0
            && x < BOARD_WIDTH && y < BOARD_HEIGHT
        ) {
            return true;
        }

        return false;
    }

    public isOccupied(
        x: number,
        y: number,
    ) {
        if (this.Teethris.board[y][x] === null) {
            return false;
        }

        return true;
    }


    public canFlipColors() {
        if (this.isRainbow) {
            return false;
        }

        if (this.isPaste) {
            return false;
        }

        return true;
    }

    public flipColors() {
        if (this.paste) {
            return false;
        }

        const colors = this.blocks.map(block => block.color);

        for (let i = 0; i < NUM_BLOCKS_IN_SHAPE; i++) {
            let color: number | null = null;

            if (i + 1 < NUM_BLOCKS_IN_SHAPE) {
                color = colors[i + 1];
            } else {
                color = colors[0];
            }

            if (typeof color !== 'number') {
                continue;
            }

            this.blocks[i].recolorBlock(color);
        }

        return true;
    }

    public recolor(
        color: number,
    ) {
        for (let i = 0; i < NUM_BLOCKS_IN_SHAPE; i++) {
            this.blocks[i].recolorBlock(color);
            this.blocks[i].sprite?.update();
        }
    }


    public blocksData() {
        const data: number[] = [];

        for (const block of this.blocks) {
            if (typeof block.color !== 'number') {
                continue;
            }

            data.push(block.color);
        }

        return data;
    }


    public toRainbow() {
        if (this.paste) {
            return;
        }

        this.isRainbow = true;

        for (let i = 0; i < NUM_BLOCKS_IN_SHAPE; i++) {
            this.blocks[i] = new Block(
                this.Teethris,
                Colors.Rainbow,
            );
        }
    }

    public toPaste() {
        this.isPaste = true;
    }

    public destroyPaste() {
        if (this.paste) {
            this.paste.destroy();
            this.paste = null;

            for (let i = 0; i < NUM_BLOCKS_IN_SHAPE; i++) {
                this.blocks[i].destroy();
            }

            this.clearActive();
        }
    }

    public getLastBlockCoords() {
        const lastBlock = this.blocks[NUM_BLOCKS_IN_SHAPE - 1];

        if (
            typeof lastBlock.x !== 'number'
            || typeof lastBlock.y !== 'number'
        ) {
            return;
        }

        return {
            x: lastBlock.x,
            y: lastBlock.y,
        };
    }



    private getRandomColor(
        position: number,
    ) {
        if (this.format) {
            return this.format[position];
        }

        const index = mathematics.random.number(
            this.availableColors.length - 1,
            0,
            true,
        );

        return index;
    }

    private initBlocks() {
        for (let i = 0; i < NUM_BLOCKS_IN_SHAPE; i++) {
            const color = this.getRandomColor(i);

            this.blocks[i] = new Block(
                this.Teethris,
                color,
            );
        }

        // this.toPaste();
    }

    private hideBlocks() {
        for (let i = 0; i < NUM_BLOCKS_IN_SHAPE; i++) {
            this.blocks[i].setForceHide();
        }
    }

    private drawPaste() {
        this.hideBlocks();

        const newX = shapeCenters.x + shapeBlockPosition[0].x;
        const newY = shapeCenters.y + shapeBlockPosition[0].y;

        const {
            xOffset,
            yOffset,
        } = this.getLocationOffsets();

        // const x = newX * 50 + 25;
        // const y = newY * 50 - 75;
        const x = xOffset + newX * blockSize * 2 + blockSize;
        const y = yOffset + newY * blockSize * 2 - blockSize * 3;

        this.paste = this.Teethris.add.sprite(
            x, y,
            spritesheetsNames.paste,
            0,
        )
        .setOrigin(0);
        if (this.Teethris.viewType === 'mobile') {
            this.paste.setDisplaySize(
                blockSize * 2,
                blockSize * 6,
            );
        }

        this.paste.play(
            animations.paste.jiggle,
        );
    }

    private getNextShapeCoordinates() {
        if (this.Teethris.viewType === 'desktop') {
            const x = 400;
            const y = 75;

            return {
                x,
                y,
            };
        }

        const nextShapeBackground = imagesCoordinates.mobile['next-shape-background'];
        const x = nextShapeBackground.offsetX * blockSize + blockSize * 2 + 5;
        const y = nextShapeBackground.offsetY * blockSize + blockSize;

        return {
            x,
            y,
        };
    }

    private getLocationOffsets() {
        if (this.Teethris?.viewType === 'desktop') {
            const xOffset = 0;
            const yOffset = 0;

            return {
                xOffset,
                yOffset,
            };
        }

        const mobileOffset = 5;
        const frameBackground = imagesCoordinates.mobile['game-background'];
        const xOffset = frameBackground.offsetX * blockSize + mobileOffset - blockSize;
        const yOffset = frameBackground.offsetY * blockSize + mobileOffset - blockSize;

        return {
            xOffset,
            yOffset,
        };
    }
}
// #endregion module



// #region exports
export default Shape;
// #endregion exports

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        cursor,
    } from '../../data/images';

    import {
        colors,
    } from '../../data/constants';
    // #endregion external
// #region imports



// #region module
export interface IStyledOptions {
}

export const StyledOptions = styled.div<IStyledOptions>`
    display: grid;
    height: 100%;
    grid-template-rows: 80px 80px 80px 200px;
    width: 700px;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 4rem;
    margin: 0 auto;
    color: ${colors.grey};

    @media (max-width: 900px) {
        padding: 0 2rem;
        width: calc(100% - 4rem);
        font-size: 0.8rem;
        grid-template-rows: initial;
        gap: 2rem;
    }
`;


export const StyledOption = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    grid-gap: 1rem;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
    }
`;


export const StyledOptionBackgroundItem = styled.div`
    position: relative;
    cursor: url(${cursor}) 16 16, pointer;
`;
// #region module

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries
// #region imports



// #region module
export interface IStyledScores {
}

export const StyledScores = styled.div<IStyledScores>`
    display: flex;
    position: absolute;
    left: 20px;
    top: 210px;
    color: white;

    @media (max-width: 800px) {
        font-size: 1.2rem;
        position: initial;
        text-align: center;
        margin-top: -1.5rem;
    }
`;


export const StyledScoreWhite = styled.div`
    margin-right: 7rem;

    @media (max-width: 800px) {
        margin-right: 2rem;
    }
`;


export const StyledText = styled.div`
    position: absolute;
    top: -10px;
    left: 177px;
    width: 350px;

    @media (max-width: 800px) {
        margin-top: 1rem;
        position: initial;
        top: initial;
        left: initial;
        widith: initial;
    }
`;


export const StyledStartButton = styled.div`
    position: absolute;
    top: -40px;
    left: -70px;

    @media (max-width: 800px) {
        left: -40px;
        top: -70px;
    }
`;
// #region module

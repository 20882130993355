// #region imports
    // #region libraries
    import {
        useState,
        useEffect,
    } from 'react';

    import Phaser from 'phaser';


    import {
        useDebouncedCallback,
    } from '@plurid/plurid-functions-react';
    // #endregion libraries


    // #region external
    import GameArea from '../../components/GameArea';
    import DevelopmentControls from '../../components/DevelopmentControls';

    import Context, {
        IContext,
    } from '../../services/context';

    import environment from '../../services/utilities/environment';
    // #endregion external


    // #region internal
    import {
        StyledGameContainer,
        StyledFontLoad,
        StyledGame,
        StyledCommand,
    } from './styled';
    // #endregion internal
// #endregion imports



// #region module
const Game = () => {
    // #region state
    const [
        game,
        setGame,
    ] = useState<Phaser.Game | undefined>();

    const [
        command,
        setCommand,
    ] = useState('');

    const [
        showCommand,
        setShowCommand,
    ] = useState(false);

    const [
        showCursor,
        setShowCursor,
    ] = useState(false);
    // #endregion state


    // #region handlers
    const hideCursor = () => {
        setTimeout(() => {
            setShowCursor(false);
        }, 2_000);
    }

    const handleShowCursor = useDebouncedCallback(
        () => {
            hideCursor();
        },
        1_000,
    );
    // #endregion handlers


    // #region effects
    useEffect(() => {
        const handleKeys = (
            event: KeyboardEvent,
        ) => {
            if (event.code === 'KeyC' && event.altKey) {
                setShowCommand(show => !show);
                return;
            }
        }

        window.addEventListener('keydown', handleKeys);

        return () => {
            window.removeEventListener('keydown', handleKeys);
        }
    }, [
        showCommand,
    ]);

    useEffect(() => {
        hideCursor();
    }, []);
    // #endregion effects


    // #region context
    const context: IContext = {
        game,
        setGame,
    };
    // #endregion context


    // #region render
    return (
        <StyledGameContainer
            onMouseMove={() => {
                if (!showCursor) {
                    setShowCursor(true);
                }

                handleShowCursor();
            }}
            style={{
                cursor: showCursor ? undefined : 'none',
            }}
        >
        <Context.Provider
            value={context}
        >
            {/* FORCED preload font for the game */}
            <StyledFontLoad>
                .
            </StyledFontLoad>

            <StyledGame>
                <GameArea />
            </StyledGame>

            {showCommand && (
                <StyledCommand>
                    <input
                        value={command}
                        placeholder="command"
                        onChange={(event) => {
                            setCommand(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.code === 'Enter') {
                                if ((window as any).game) {
                                    (window as any).game.emit(command);
                                }

                                setCommand('');
                            }
                        }}
                    />
                </StyledCommand>
            )}

            {/* {environment.development && (
                <DevelopmentControls />
            )} */}
        </Context.Provider>
        </StyledGameContainer>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Game;
// #endregion exports

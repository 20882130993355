// #region imports
    // #region libraries
    import React from 'react';
    // #endregion libraries


    // #region external
    import {
        toothbrush,
    } from '../../data/images';
    // #endregion external


    // #region internal
    import {
        StyledBrush,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface BrushProperties {
    index: number;
}

const Brush: React.FC<BrushProperties> = (
    properties,
) => {
    // #region properties
    const {
        index,
    } = properties;

    const image = toothbrush[index];
    // #endregion properties


    // #region render
    return (
        <StyledBrush>
            <img
                src={image}
                alt="brush"
            />
        </StyledBrush>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Brush;
// #endregion exports

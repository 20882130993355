// #region imports
    // #region libraries
    import {
        mathematics,
    } from '@plurid/plurid-functions';
    // #endregion libraries


    // #region external
    import {
        Highscore,
    } from '../../../data/interfaces';

    import {
        teethColorsIndex,

        WHITE_TEETH_SCORE,
        COLORED_TEETH_SCORE,
    } from '../../../data/constants/game';
    // #endregion external
// #endregion imports



// #region module
export const getRandomMessage = (
    list: string[],
) => {
    if (list.length === 1) {
        return list[0];
    }

    const index = mathematics.random.number(list.length - 1, 0, true);
    const message = list[index];

    return message;
}


export const displayScore = (
    value: number,
) => {
    return (value + '').padStart(5, '0');
}


export const displayNameField = (
    value: string,
) => {
    return value.padEnd(8, '_');
}

export const displayNameFlicker = (
    value: string,
    flicker: boolean,
) => {
    if (flicker) {
        const intermediary = value.padEnd(8, '_');
        const final = intermediary.replace('_', '|');
        return final;
    }

    return displayNameField(value);
}


export const padHighscores = (
    highscores: Highscore[],
) => {
    if (highscores.length > 10) {
        return highscores.slice(0, 10);
    }

    if (highscores.length === 10) {
        return highscores;
    }

    const emptyHighscores: Highscore[] = [];
    const difference = 10 - highscores.length;
    const emptyHighscore: Highscore = {
        name: '<none>',
        score: 0,
    };

    for (let i = 0; i < difference; i++) {
        emptyHighscores.push(emptyHighscore);
    }

    return [
        ...highscores,
        ...emptyHighscores,
    ];
}


export const getBlockScoreValue = (
    color: number,
) => {
    if (color === teethColorsIndex.white) {
        return WHITE_TEETH_SCORE;
    }

    return COLORED_TEETH_SCORE;
}
// #endregion module

// #region imports
    // #region external
    import {
        Board,
    } from '../../../data/interfaces';

    import {
        WHITE_TEETH_SCORE,
        COLORED_TEETH_SCORE,
    } from '../../../data/constants/game';

    // import {
    //     logBoard,
    // } from '../../services/utilities/testing';
    // #endregion external
// #endregion imports



// #region module
export const clearGroups = (
    groups: number[][][],
    ids: string[],
    board: Board,
    increaseScore?: (
        groupLength: number,
        groupValue: number,
    ) => void,
) => {
    let currentBoard = board;
    const affectedColumns = new Set<number>();
    const colors: Record<number, number | undefined> = {};


    const increaseColorCount = (
        color: number | null,
    ) => {
        if (typeof color !== 'number') {
            return;
        }

        if (colors[color]) {
            colors[color] = (colors[color] as number) + 1;
        }  else {
            colors[color] = 1;
        }
    }

    for (const group of groups) {
        let groupValue = COLORED_TEETH_SCORE;

        for (const block of group) {
            const [
                row, column,
            ] = block;

            if (board[row]) {
                let block = board[row][column];
                if (!block) {
                    continue;
                }

                if (
                    ids.includes(block.id)
                ) {
                    if (block.color === 0) {
                        groupValue = WHITE_TEETH_SCORE;
                    }

                    increaseColorCount(block.color);

                    affectedColumns.add(column);

                    block.clean();
                    board[row][column] = null;
                }
            }
        }


        if (increaseScore) {
            increaseScore(group.length, groupValue);
        }
    }

    return {
        board: currentBoard,
        affectedColumns,
        colors,
    };
}



export const findNextBlockInColumn = (
    row: number,
    column: number,
    ids: string[],
    board: Board,
) => {
    let currentRow = row - 1;
    const nextRow = board[currentRow];
    if (!nextRow) {
        return;
    }

    let block = nextRow[column];

    while (currentRow >= 0) {
        if (!block || ids.includes(block.id)) {
            currentRow = currentRow - 1;
            const nextRow = board[currentRow];
            if (!nextRow) {
                return;
            }
            block = nextRow[column];
            continue;
        }

        return {
            row: currentRow,
            column,
        };
    }

    return;
}
// #endregion module

// #region imports
    // #region libraries
    import {
        useState,
        useEffect,
    } from 'react';
    // #endregion libraries
// #endregion imports



// #region module
export interface PropertiesUseSelected {
    maximumValue: number;
    initialValue?: number;

    action?: (
        event: KeyboardEvent,
        selected: number,
    ) => void;
}

export const useSelected = (
    properties: PropertiesUseSelected,
) => {
    // #region properties
    const {
        initialValue,
        maximumValue,
        action,
    } = properties;
    // #endregion properties


    // #region state
    const [
        selected,
        setSelected,
    ] = useState(initialValue ?? 0);
    // #endregion state


    // #region effects
    useEffect(() => {
        const arrowBinds = (
            event: KeyboardEvent,
        ) => {
            if (event.code === 'ArrowUp') {
                if (selected === 0) {
                    return;
                }

                const previous = selected - 1;
                if (previous < 0) {
                    return;
                }

                setSelected(previous);
            }

            if (event.code === 'ArrowDown') {
                if (selected === maximumValue) {
                    return;
                }

                const next = selected + 1;
                if (next > maximumValue) {
                    return;
                }

                setSelected(next);

                return;
            }

            if (action) {
                action(
                    event,
                    selected,
                );
            }
        }

        window.addEventListener('keydown', arrowBinds);

        return () => {
            window.removeEventListener('keydown', arrowBinds);
        }
    }, [
        selected,
        maximumValue,
        action,
    ]);
    // #endregion effects


    return {
        selected,
        setSelected,
    };
}


export const useEnter = (
    action: (
        event: KeyboardEvent,
    ) => void,
) => {
    useEffect(() => {
        const keyBinds = (
            event: KeyboardEvent,
        ) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                action(event);
                return;
            }
        }

        window.addEventListener('keydown', keyBinds);

        return () => {
            window.removeEventListener('keydown', keyBinds);
        }
    }, [
        action,
    ]);
}
// #endregion module

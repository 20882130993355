// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';
    // #endregion libraries


    // #region external
    import landscapeWarning from '../../assets/images/ui/landscape-warning.png';
    // #endregion external


    // #region internal
    import {
        StyledLandscapeWarning,
        StyledLandscapeWarningText,
        DisableScroll,
        EnableScroll,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface LandscapeWarningProperties {
}

const LandscapeWarning: React.FC<LandscapeWarningProperties> = (
    _properties,
) => {
    // #region handlers
    const checkShowWarning = () => {
        if (typeof window === 'undefined') {
            return false;
        }

        const isPortrait = window.orientation === 0;
        const availableHeight = window.innerHeight > 700;

        return !isPortrait && !availableHeight;
    }
    // #endregion handlers


    // #region state
    const [
        showWarning,
        setShowWarning,
    ] = useState(checkShowWarning());
    // #endregion state


    // #region effects
    useEffect(() => {
        const handleOrientation = () => {
            if (checkShowWarning()) {
                window.scrollTo(0, 0);
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        }

        window.addEventListener('orientationchange', handleOrientation, false);

        return () => {
            window.removeEventListener('orientationchange', handleOrientation);
        }
    }, []);
    // #endregion effects


    // #region render
    if (!showWarning) {
        return (<EnableScroll />);
    }

    return (
        <>
            <DisableScroll />

            <StyledLandscapeWarning>
                <StyledLandscapeWarningText>
                    <div>
                        use portrait
                        <br />
                        to play teethris
                    </div>

                    <img
                        src={landscapeWarning}
                        alt="landscape warning"
                    />
                </StyledLandscapeWarningText>
            </StyledLandscapeWarning>
        </>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default LandscapeWarning;
// #endregion exports

// #region imports
    // #region libraries
    import {
        arrays,
    } from '@plurid/plurid-functions';
    // #endregion libraries


    // #region external
    // eslint-disable-next-line
    import environment from '../../../../services/utilities/environment';
    // #endregion external
// #endregion imports



// #region module
export const NUM_BLOCKS_IN_SHAPE = 3;
export const NUM_COLORS = 6;

export const BOARD_WIDTH = 6;
export const BOARD_HEIGHT = 13 + 2;

export const BLOCK_WIDTH = 50;
export const BANNER_HEIGHT = 50;
export const LINING_WIDTH = 50;


export const availableColorsList = arrays.range(0, NUM_COLORS);


export const shapeCenters = {
    x: Math.floor(BOARD_WIDTH / 2),
    y: 2,
};

export const shapeBlockPosition = [
    { x: 0, y: -2 },
    { x: 0, y: -1 },
    { x: 0, y: 0 },
    { x: 0, y: 1 },
];


// export const blockShowLabels = environment.development;
export const blockShowLabels = false;
// #endregion module

// #region imports
    // #region libraries
    import React from 'react';

    import {
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import Penguin from '../../../../components/Penguin';
    import Brush from '../../../../components/Brush';
    import Tooth from '../../../../components/Tooth';

    import StartButton from '../../../../components/StartButton';

    import {
        useEnter,
    } from '../../../../services/hooks';

    import {
        StyledStoryScreen,
        StyledStoryScreenContainer,
        StyledStoryScreenText,
        StyledStoryScreenButtons,
        StyledStoryScreenButtonsGroup,
    } from '../../styled';

    import ResponsiveSpacer from '../ResponsiveSpacer';
    // #endregion external


    // #region internal
    import {
        StyledScores,
        StyledScoreWhite,
        StyledText,
        StyledStartButton,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
const FourthScreen: React.FC<any> = () => {
    // #region effects
    const navigate = useNavigate();

    useEnter(
        () => {
            navigate('/game');
        },
    );
    // #endregion effects


    // #region render
    return (
        <StyledStoryScreen>
            <StyledStoryScreenContainer>
                <Penguin />

                <div
                    style={{
                        display: 'flex',
                        gap: '25px',
                    }}
                >
                    <Tooth
                        color="white"
                    />

                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Tooth
                            color="orange"
                        />
                        <Tooth
                            color="red"
                        />
                        <Tooth
                            color="purple"
                        />
                        <Tooth
                            color="blue"
                        />
                        <Tooth
                            color="green"
                        />
                    </div>
                </div>
            </StyledStoryScreenContainer>


            <StyledScores>
                <StyledScoreWhite>
                    10 points
                </StyledScoreWhite>

                <div>
                    5 points
                </div>
            </StyledScores>


            <StyledText>
                <StyledStoryScreenText>
                    <p>
                        These are my teethy friends.
                        <br />
                        They help you <span
                            className="white-text"
                        >
                            score points
                        </span>.
                        <br />
                        <br />
                        Let's go!
                    </p>
                </StyledStoryScreenText>
            </StyledText>


            <ResponsiveSpacer />


            <StyledStoryScreenButtons>
                <Brush
                    index={2}
                />

                <StyledStoryScreenButtonsGroup
                    lastScreen={true}
                >
                    <div />

                    <div
                        style={{
                            position: 'relative',
                            height: '40px',
                        }}
                    >
                        <StyledStartButton>
                            <StartButton
                                penguin={false}
                                link={'/game'}
                            />
                        </StyledStartButton>
                    </div>
                </StyledStoryScreenButtonsGroup>
            </StyledStoryScreenButtons>
        </StyledStoryScreen>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default FourthScreen;
// #endregion exports

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        cursor,
    } from '../../data/images';
    // #endregion external
// #region imports



// #region module
export const StyledStartButtonContainer = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 800px) {
        transform: scale(0.7);
    }
`;


export interface IStyledStartButton {
}

export const StyledStartButton = styled.div<IStyledStartButton>`
    cursor: url(${cursor}) 16 16, pointer;
    user-select: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding-left: 1rem;

    :focus {
        outline: none;
    }

    img {
        outline: none;
        border: none;
    }
`;
// #region module

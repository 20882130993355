// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';

    import {
        Link,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import {
        buttonStart,
        buttonStartClose,
        buttonStartOpen,
    } from '../../data/images';

    import Penguin from '../Penguin';
    // #endregion external


    // #region internal
    import {
        StyledStartButtonContainer,
        StyledStartButton,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface StartScreenProperties {
    penguin?: boolean;
    link?: string;
}

const StartScreen: React.FC<StartScreenProperties> = (
    properties,
) => {
    // #region properties
    const {
        penguin,
        link,
    } = properties;

    const showPenguin = penguin ?? true;
    const linkValue = link || '/story';
    // #endregion properties


    // #region state
    const [
        startAnimationFrame,
        setStartAnimationFrame,
    ] = useState(0);

    const [
        startHovered,
        setStartHovered,
    ] = useState(false);
    // #endregion state


    // #region effects
    useEffect(() => {
        const interval = setInterval(() => {
            setStartAnimationFrame(value => {
                if (value >= 5) {
                    return 0;
                }

                return value + 1;
            });
        }, 500);

        return () => {
            clearInterval(interval);
        }
    }, []);
    // #endregion effects


    // #region render
    return (
        <StyledStartButtonContainer>
            {showPenguin && (
                <div
                    style={{
                        marginTop: '-1.5rem',
                    }}
                >
                    <Penguin
                        full={true}
                    />
                </div>
            )}

            <Link
                to={linkValue}
                style={{
                    color: 'white',
                }}
            >
                <StyledStartButton
                    onMouseEnter={() => {
                        setStartHovered(true);
                    }}
                    onMouseLeave={() => {
                        setStartHovered(false);
                    }}
                    onMouseMove={() => {
                        if (!startHovered) {
                            setStartHovered(true);
                        }
                    }}
                    onMouseDown={(
                        event,
                    ) => {
                        event.preventDefault();
                        setStartHovered(true);
                    }}
                    onMouseUp={(
                        event,
                    ) => {
                        event.preventDefault();
                        setStartHovered(false);
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${buttonStart})`,
                            width: '206px',
                            height: '108px',
                            backgroundPosition: `${startAnimationFrame * -206}px 0px`,
                        }}
                    />
                    <img
                        src={startHovered
                            ? buttonStartOpen
                            : buttonStartClose
                        }
                        alt="start game"
                    />
                </StyledStartButton>
            </Link>
        </StyledStartButtonContainer>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default StartScreen;
// #endregion exports

// #region imports
    // #region external
    import environment from '../../../services/utilities/environment';

    import {
        BOARD_HEIGHT,
        BOARD_WIDTH,
    } from '../game';
    // #endregion external
// #endregion imports



// #region module
export const applicationRoot = 'teethris';
export const gameRoot = 'teethris-game';

export const gameLoadingTime = environment.production ? 700 : 0; // ms

export const gameBoardWidth = BOARD_WIDTH * 50 + 460;
export const gameBoardHeight = BOARD_HEIGHT * 50 - 50;



export const backgroundLight = '#8B9296';
export const backgroundDark = '#676767';

export const white = '#ffffff';
export const black = '#000000';
export const grey = '#8b9296';

export const red = '#ec2873';
export const yellow = '#fec300';
export const green = '#65db6b';
export const blue = '#008ffd';
export const pink = '#ff94fd';


export const colors = {
    backgroundLight,
    backgroundDark,

    white,
    black,
    grey,

    red,
    yellow,
    green,
    blue,
    pink,
};


export const fonts = {
    Textfont: `'Textfont', monospace`,
    Gamefont: `'Gamefont', monospace`,
};


export const mobileScale = typeof window === 'undefined'
    ? false
    : window.innerWidth < 800;
// #endregion module

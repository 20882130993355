// #region imports
    // #region external
    import {
        TEETHRIS_SERVER,
    } from '../../data/constants';

    import {
        Highscore,
    } from '../../data/interfaces';
    // #endregion external
// #endregion imports



// #region module
export const getHighscores = async (): Promise<Highscore[]> => {
    try {
        const request = await fetch(TEETHRIS_SERVER);
        const json = await request.json();
        if (json.status === false) {
            return [];
        }

        return json as Highscore[];
    } catch (error) {
        return [];
    }
}

export const saveHighscore = async (
    highscore: Highscore,
): Promise<any> => {
    try {
        const request = await fetch(
            TEETHRIS_SERVER,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(highscore),
            },
        );

        const sucess = request.status === 200;

        return sucess;
    } catch (error) {
        return;
    }
}
// #endregion module

// #region imports
    // #region libraries
    import {
        useContext,
        useState,
        useRef,
        useEffect,
    } from 'react';

    import Phaser from 'phaser';
    // #endregion libraries


    // #region external
    import {
        gameRoot,
        gameLoadingTime,

        gameBoardWidth,
        gameBoardHeight,

        mobileScale,
    } from '../../data/constants';

    import {
        backgrounds,
    } from '../../data/images';

    import Spinner from '../../components/Spinner';
    import GamePaused from '../../components/GamePaused';

    import Teethris from '../../game/Teethris';

    import Context from '../../services/context';

    import {
        localStorer,
    } from '../../services/objects';
    // #endregion external


    // #region internal
    import {
        StyledGameArea,
    } from './styled';
    // #endregion internal
// #endregion imports



// #region module
const gameConfiguration = {
    type: Phaser.AUTO,
    parent: gameRoot,
    banner: {
        hidePhaser: true,
    },
    input: {
        touch: {
            capture: true,
        },
    },
    scale: {
        mode: mobileScale ? Phaser.Scale.MAX_ZOOM : Phaser.Scale.NONE,
    },
    transparent: true,
    width: mobileScale ? window.innerWidth : gameBoardWidth,
    height: mobileScale ? window.innerHeight : gameBoardHeight,
    scene: Teethris,
};

const gameWindowDefineTimeout = 1_000;


const GameArea = () => {
    // #region context
    const context = useContext(Context);
    // #endregion context


    // #region references
    const game = useRef<Phaser.Game | null>(null);
    // #endregion references


    // #region state
    const [
        activeBackground,
        setActiveBackground,
    ] = useState(0);

    const [
        loaded,
        setLoaded,
    ] = useState(false);

    const [
        gameLost,
        setGameLost,
    ] = useState(false);

    const [
        gamePaused,
        setGamePaused,
    ] = useState(false);
    // #endregion state


    // #region effects
    /** Background */
    useEffect(() => {
        const gameBackground = localStorer.getBackground();
        if (typeof gameBackground === 'number') {
            setActiveBackground(gameBackground);
        }

        localStorer.onBackground((gameBackground) => {
            setActiveBackground(gameBackground);
        });
    }, []);

    /** Start Game */
    useEffect(() => {
        if (!context) {
            return;
        }

        game.current = new Phaser.Game(gameConfiguration);
        context.setGame(game.current);

        // allow page scrolling
        if (game.current.canvas) {
            game.current.canvas.style.pointerEvents = 'none';
        }


        setTimeout(() => {
            if (!game.current) {
                return;
            }

            const teethris = game.current.scene.scenes[0];
            if (!teethris) {
                return;
            }

            (window as any).game = teethris;
        }, gameWindowDefineTimeout);


        return () => {
            if (game.current) {
                game.current.destroy(true, false);
                game.current.canvas.parentNode?.removeChild(game.current.canvas);

                (window as any).game = undefined;
            }
        }
    // eslint-disable-next-line
    }, []);

    /** Loaded */
    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, gameLoadingTime);
    }, []);

    /** Game Lost */
    useEffect(() => {
        localStorer.onGameLost((gameLost) => {
            if (gameLost) {
                setGameLost(true);
            }
        });
    }, []);

    /** Game Paused */
    useEffect(() => {
        localStorer.onGamePaused((gamePaused) => {
            setGamePaused(gamePaused);
        });
    }, []);
    // #endregion effects


    // #region render
    return (
        <>
            {!loaded && (
                <Spinner />
            )}

            <StyledGameArea
                id={gameRoot}
                style={{
                    visibility: loaded ? 'initial' : 'hidden',
                    backgroundImage: loaded && !gameLost ? `url(${backgrounds[activeBackground]})` : undefined,
                    backgroundSize: mobileScale ? '30px' : '50px',
                    backgroundColor: loaded && gameLost ? 'black' : undefined,
                    height: mobileScale ? window.innerHeight : undefined,
                    width: mobileScale ? window.innerWidth : undefined,
                }}
            />

            {gamePaused && (
                <GamePaused />
            )}
        </>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default GameArea;
// #endregion exports

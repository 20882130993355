// #region imports
    // #region libraries
    import React from 'react';
    // #endregion libraries


    // #region external
    import {
        checkedImage,
        notCheckedImage,
    } from '../../data/images';
    // #endregion external


    // #region internal
    import {
        StyledSwitch,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface SwitchProperties {
    checked: boolean;
    atClick: () => void;
}

const Switch: React.FC<SwitchProperties> = (
    properties,
) => {
    // #region properties
    const {
        checked,
        atClick,
    } = properties;

    const image = checked ? checkedImage : notCheckedImage;
    // #endregion properties


    // #region render
    return (
        <StyledSwitch
            onClick={() => {
                atClick();
            }}
        >
            <img
                src={image}
                alt="check"
            />
        </StyledSwitch>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Switch;
// #endregion exports

// #region imports
    // #region external
    import {
        BLOCK_WIDTH,
    } from '../board';
    // #endregion external
// #endregion imports



// #region module
export const defaultBlockOptions = {
    frameWidth: BLOCK_WIDTH,
    frameHeight: BLOCK_WIDTH,
};



export const spritesheetsNames = {
    teeth: 'teeth',
    cleaning: 'cleaning',
    frame: 'frame',
    framePenguin: 'frame-penguin',
    paste: 'paste',
    bubbles: 'bubbles',
    penguin: 'penguin',
    penguinIdle: 'penguin-idle',
    penguinGameOver: 'penguin-game-over',
};

export const spritesheetsData = {
    teeth: {
        name: spritesheetsNames.teeth,
        path: `/game/${spritesheetsNames.teeth}.png`,
        options: {
            ...defaultBlockOptions,
        },
    },
    cleaning: {
        name: spritesheetsNames.cleaning,
        path: `/game/${spritesheetsNames.cleaning}.png`,
        options: {
            ...defaultBlockOptions,
        },
    },
    frame: {
        name: spritesheetsNames.frame,
        path: `/game/${spritesheetsNames.frame}.png`,
        options: {
            ...defaultBlockOptions,
        },
    },
    framePenguin: {
        name: spritesheetsNames.framePenguin,
        path: `/game/${spritesheetsNames.framePenguin}.png`,
        options: {
            frameWidth: 33.5,
            frameHeight: BLOCK_WIDTH,
        },
    },
    paste: {
        name: spritesheetsNames.paste,
        path: `/game/${spritesheetsNames.paste}.png`,
        options: {
            frameWidth: 50,
            frameHeight: 150,
        },
    },
    bubbles: {
        name: spritesheetsNames.bubbles,
        path: `/game/${spritesheetsNames.bubbles}.png`,
        options: {
            ...defaultBlockOptions,
        },
    },
    penguin: {
        name: spritesheetsNames.penguin,
        path: `/game/${spritesheetsNames.penguin}.png`,
        options: {
            frameWidth: 100,
            frameHeight: 150,
        },
    },
    penguinIdle: {
        name: spritesheetsNames.penguinIdle,
        path: `/game/${spritesheetsNames.penguinIdle}.png`,
        options: {
            frameWidth: 100,
            frameHeight: 150,
        },
    },
    penguinGameOver: {
        name: spritesheetsNames.penguinGameOver,
        path: `/game/${spritesheetsNames.penguinGameOver}.png`,
        options: {
            frameWidth: 100,
            frameHeight: 150,
        },
    },
};



export const imagesNames: {
    mouth: 'mouth';
    scoreTooth: 'score-tooth';
    toothpaste: 'toothpaste';
    brush: 'brush';
    logo: 'logo';
    logoWhite: 'logo-white';
    gameBackground: 'game-background';
    nextShapeBackground: 'next-shape-background';
    penguinFrameBackground: 'penguin-frame-background';
} = {
    mouth: 'mouth',
    scoreTooth: 'score-tooth',
    toothpaste: 'toothpaste',
    brush: 'brush',
    logo: 'logo',
    logoWhite: 'logo-white',
    gameBackground: 'game-background',
    nextShapeBackground: 'next-shape-background',
    penguinFrameBackground: 'penguin-frame-background',
};

export const imagesData = {
    mouth: {
        name: imagesNames.mouth,
        path: `/game/${imagesNames.mouth}.png`,
    },
    scoreTooth: {
        name: imagesNames.scoreTooth,
        path: `/game/${imagesNames.scoreTooth}.png`,
    },
    toothpaste: {
        name: imagesNames.toothpaste,
        path: `/game/${imagesNames.toothpaste}.png`,
    },
    brush: {
        name: imagesNames.brush,
        path: `/game/${imagesNames.brush}.png`,
    },
    logo: {
        name: imagesNames.logo,
        path: `/game/teethris-${imagesNames.logo}.png`,
    },
    logoWhite: {
        name: imagesNames.logoWhite,
        path: `/game/teethris-${imagesNames.logoWhite}.png`,
    },
};


export const imagesCoordinates = {
    desktop: {
        mouth: {
            x: 25,
            y: 25,
        },
        'score-tooth': {
            x: 375,
            y: 495,
        },
        toothpaste: {
            x: 375,
            y: 550,
        },
        brush: {
            x: 375,
            y: 650,
        },
        logo: {
            x: 580,
            y: 0,
        },
        'game-background': {
            x: 25,
            y: 25,
        },
        'next-shape-background': {
            x: 350,
            y: 50,
        },
        'penguin-frame-background': {
            x: 410 + 15,
            y: 325,
        },
        scoreLiteralText: {
            x: 425 + 15,
            y: 575 + 15,
        },
        scoreText: {
            x: 425 + 15,
            y: 600 + 15,
        },
        teethText: {
            x: 425 + 15,
            y: 495 + 15,
        },
        penguin: {
            x: 620,
            y: 289,
        },
        'penguin-restart': {
            x: 300,
            y: 300,
        },
        'penguin-restart-text': {
            x: 325,
            y: 225,
        },
        penguinText: {
            x: 435,
            y: 345,
        },
        'bubbles-0': {
            x: 620,
            y: 620,
        },
        'bubbles-1': {
            x: 660,
            y: 580,
        },
        'bubbles-2': {
            x: 720,
            y: 600,
        },
        'bubbles-3': {
            x: 680,
            y: 610,
        },
        'bubbles-4': {
            x: 700,
            y: 540,
        },
        gameOver: {
            x: 135,
            y: 60,
        },
        finishScreenScoreText: {
            x: 115,
            y: 200,
        },
        finishScreenValueText: {
            x: 115,
            y: 240,
        },
        nameText: {
            x: 135,
            y: 340,
        },
        playerNameText: {
            x: 95,
            y: 390,
        },
        retryText: {
            x: 127.5,
            y: 490,
        },
        quitGameText: {
            x: 87.5,
            y: 590,
        },
        highscoreGeneral: {
            y: 120,
        },
        highscoreIndex: {
            x: 40,
        },
        highscoreName: {
            x: 85,
        },
        highscoreScore: {
            x: 240,
        },
        comboText: {
            x: 35,
            y: 35,
        },
    },
    mobile: {
        mouth: {
            offsetX: 7,
            offsetY: 6,
        },
        'score-tooth': {
            offsetX: 6.3,
            offsetY: 1.7,
        },
        toothpaste: {
            offsetX: 7,
            offsetY: 34,
        },
        brush: {
            offsetX: 3.5,
            offsetY: 20.3,
        },
        logo: {
            x: 100,
            y: 1.8,
            offsetX: -7,
            offsetY: 0,
        },
        'game-background': {
            offsetX: 7,
            offsetY: 6,
        },
        'next-shape-background': {
            offsetX: 20,
            offsetY: 7.25,
        },
        'penguin-frame-background': {
            offsetX: 7,
            offsetY: 40,
        },
        scoreLiteralText: {
            offsetX: 9.2,
            offsetY: 35.5,
        },
        scoreText: {
            offsetX: 9.2,
            offsetY: 36.7,
        },
        teethText: {
            offsetX: 9,
            offsetY: 2.3,
        },
        penguin: {
            offsetX: 16,
            offsetY: 38,
        },
        'penguin-restart': {
            x: 40,
            y: 40,
            scale: {
                x: 0.7,
                y: 0.7,
            },
        },
        'penguin-restart-text': {
            x: 48,
            y: 35,
        },
        penguinText: {
            offsetX: 7.3,
            offsetY: 41,
            scale: {
                x: 0.7,
                y: 0.7,
            },
        },
        'bubbles-0': {
            offsetX: 2.5,
            offsetY: 1.5,
        },
        'bubbles-1': {
            offsetX: 1,
            offsetY: 2.8,
        },
        'bubbles-2': {
            offsetX: 0.5,
            offsetY: 4.5,
        },
        'bubbles-3': {
            offsetX: 0.5,
            offsetY: 1,
        },
        'bubbles-4': {
            offsetX: 1,
            offsetY: 6,
        },
        gameOver: {
            offsetX: 11.5,
            offsetY: 8,
        },
        finishScreenScoreText: {
            offsetX: 10.5,
            offsetY: 13,
        },
        finishScreenValueText: {
            offsetX: 10.5,
            offsetY: 15,
        },
        nameText: {
            offsetX: 11.5,
            offsetY: 19,
        },
        playerNameText: {
            offsetX: 9.5,
            offsetY: 21,
        },
        retryText: {
            offsetX: 11.5,
            offsetY: 25,
        },
        quitGameText: {
            offsetX: 9.5,
            offsetY: 29,
        },
        highscoreGeneral: {
            offsetY: 11,
        },
        highscoreIndex: {
            offsetX: 8,
        },
        highscoreName: {
            offsetX: 10,
        },
        highscoreScore: {
            offsetX: 15.5,
        },
        comboText: {
            offsetX: 7.8,
            offsetY: 6.8,
        },
    },
};



export const audioNames = {
    colorChange: 'color-change',
    pieceTouch: 'piece-touch',
    score: 'score',
    special: 'special',
    endgame: 'endgame',
};

export const audioData = {
    colorChange: {
        name: audioNames.colorChange,
        path: `/game/${audioNames.colorChange}.wav`,
    },
    pieceTouch: {
        name: audioNames.pieceTouch,
        path: `/game/${audioNames.pieceTouch}.wav`,
    },
    score: {
        name: audioNames.score,
        path: `/game/${audioNames.score}.wav`,
    },
    special: {
        name: audioNames.special,
        path: `/game/${audioNames.special}.wav`,
    },
    endgame: {
        name: audioNames.endgame,
        path: `/game/${audioNames.endgame}.wav`,
    },
};



export const audioVolume = 0.5;
// #endregion module

// #region imports
    // #region external
    import {
        colors,
        fonts,
    } from '../../ui';
    // #endregion external
// #endregion imports



// #region module
export const messageStyleLineSpacing = 6;


export const messageDefaultStyle = {
    fontSize: '20px',
    // color: '#658df2',
    color: colors.blue,
    fontFamily: fonts.Gamefont,
    lineSpacing: messageStyleLineSpacing,
};

export const messageScoreStyle = {
    fontSize: '50px',
    color: colors.white,
    fontFamily: fonts.Gamefont,
    lineSpacing: messageStyleLineSpacing,
};

export const messageRandomStyle = {
    fontSize: '20px',
    color: colors.white,
    fontFamily: fonts.Gamefont,
    lineSpacing: messageStyleLineSpacing,
};


export const initialMessage = 'hello,\nworld!';

export const randomMessages = [
    'smile\nbright!',
    'keep\nsmiling!',
    'shine\nbright!',
];

export const congratulationMessages = [
    'good\njob!',
    'nice\nmove!',
    'great\nteeth!',
];

export const finishMessages = [
    'try\nagain:(',
];


export const randomMessageInterval = 5_000; // ms
export const scoreMessageDuration = 1_500; // ms
export const congratulationMessageDuration = 2_000; // ms
// #endregion module

// #region imports
    // #region libraries
    import React from 'react';

    import Phaser from 'phaser';
    // #endregion libraries
// #endregion imports



// #region module
export interface IContext {
    game: Phaser.Game | undefined;
    setGame: React.Dispatch<React.SetStateAction<Phaser.Game | undefined>>;
}

const Context = React.createContext<IContext | null>(null);
// #endregion module



// #region exports
export default Context;
// #endregion exports

// #region module
class LocalStorer {
    private onMusicCalls: ((value: boolean) => void)[] = [];
    private onGameLostCalls: ((value: boolean) => void)[] = [];
    private onGamePausedCalls: ((value: boolean) => void)[] = [];
    private onBackgroundCalls: ((value: number) => void)[] = [];



    public getBackground() {
        const gameBackground = localStorage.getItem('teethris-background');
        if (!gameBackground) {
            return;
        }

        return parseInt(gameBackground);
    }

    public setBackground(
        value: number,
    ) {
        localStorage.setItem('teethris-background', value + '');

        for (const onBackgroundCall of this.onBackgroundCalls) {
            onBackgroundCall(value);
        }
    }

    public onBackground(
        action: (value: number) => void,
    ) {
        this.onBackgroundCalls.push(action);
    }


    public getMusic() {
        const gameMusic = localStorage.getItem('teethris-music');
        if (!gameMusic) {
            return;
        }

        return gameMusic === 'true';
    }

    public setMusic(
        value: boolean,
    ) {
        localStorage.setItem('teethris-music', JSON.stringify(value));

        for (const onMusicCall of this.onMusicCalls) {
            onMusicCall(value);
        }
    }

    public onMusic(
        action: (value: boolean) => void,
    ) {
        this.onMusicCalls.push(action);
    }


    public getSound() {
        const gameSound = localStorage.getItem('teethris-sound');
        if (!gameSound) {
            return;
        }

        return gameSound === 'true';
    }

    public setSound(
        value: boolean,
    ) {
        localStorage.setItem('teethris-sound', JSON.stringify(value));
    }


    public getGameLost() {
        const gameLostData = localStorage.getItem('teethris-gameLost');
        if (!gameLostData) {
            return;
        }

        return gameLostData === 'true';
    }

    public setGameLost(
        value: boolean,
    ) {
        localStorage.setItem('teethris-gameLost', JSON.stringify(value));

        for (const onGameLostCall of this.onGameLostCalls) {
            onGameLostCall(value);
        }
    }

    public onGameLost(
        action: (value: boolean) => void,
    ) {
        this.onGameLostCalls.push(action);
    }


    public getGamePaused() {
        const gamePausedData = localStorage.getItem('teethris-gamePaused');
        if (!gamePausedData) {
            return;
        }

        return gamePausedData === 'true';
    }

    public setGamePaused(
        value: boolean,
    ) {
        localStorage.setItem('teethris-gamePaused', JSON.stringify(value));

        for (const onGamePausedCall of this.onGamePausedCalls) {
            onGamePausedCall(value);
        }
    }

    public onGamePaused(
        action: (value: boolean) => void,
    ) {
        this.onGamePausedCalls.push(action);
    }



    public setScore(
        value: number,
    ) {
        localStorage.setItem('teethris-score', JSON.stringify(value));
    }

    public setComboLevel(
        value: number,
    ) {
        localStorage.setItem('teethris-comboLevel', JSON.stringify(value));
    }

    public setNextShape(
        value: string,
    ) {
        localStorage.setItem('teethris-nextShape', value);
    }
}


const localStorer = new LocalStorer();
// #endregion module



// #region exports
export {
    localStorer,
};
// #endregion exports

// #region imports
    // #region libraries
    import Phaser from 'phaser';


    import {
        meta,
    } from '@plurid/plurid-functions';
    // #endregion libraries


    // #region external
    import Block from '../Block';
    import Shape from '../Shape';

    import {
        BOARD_HEIGHT,
        BOARD_WIDTH,

        spritesheetsNames,
        spritesheetsData,
        imagesNames,
        imagesData,
        imagesCoordinates,
        audioNames,
        audioData,
        audioVolume,

        DROPPING_TIME,
        COMBO_HANGING_TIME,
        COMBO_RAINBOW_CHANCE,
        COMBO_LEVEL_FOAM,

        shapeCenters,

        teethColorsIndex,
        colorsIndex,

        colorsNames,
        colorFrames,

        cleaningAnimationFrames,

        Directions,

        degrees,

        CORNER_ADJUSTMENT,

        messageDefaultStyle,
        messageScoreStyle,
        messageRandomStyle,
        initialMessage,
        randomMessages,
        congratulationMessages,
        finishMessages,
        randomMessageInterval,
        scoreMessageDuration,
        congratulationMessageDuration,

        animations,

        // speedIncreases,
        blockSize,
        mobileWidthSteps,
    } from '../../data/constants/game';

    import {
        colors,
        hexBlackColor,

        bubbles,

        gameBoardWidth,
        gameBoardHeight,

        mobileScale,
    } from '../../data/constants';

    import {
        Board,
        Highscore,
    } from '../../data/interfaces';

    import environment from '../../services/utilities/environment';

    import {
        getHighscores,
        saveHighscore,
    } from '../../services/server';

    import {
        localStorer,
    } from '../../services/objects';

    // import {
    //     logBoard,
    // } from '../../services/utilities/testing';
    // #endregion external


    // #region internal
    import {
        getCompleteGroups,
    } from './logic/completion';

    import {
        clearGroups,
        findNextBlockInColumn,
    } from './logic/clearing';

    import {
        getRandomMessage,
        displayScore,
        displayNameField,
        displayNameFlicker,
        padHighscores,
        getBlockScoreValue,
    } from './logic/utility';

    import PhaserSwipe from './vendors/PhaserSwipe';

    import {
        scenario1,
    } from './__tests__/scenarios';
    // #endregion internal
// #endregion imports



// #region module
class Teethris extends Phaser.Scene {
    // #region properties
    private cursors: Phaser.Types.Input.Keyboard.CursorKeys | null = null;
    private activeShape: Shape | null = null;
    private nextShape: Shape | null = null;
    private shapeCount = 0;
    private teethCount = 0;
    private fontSize = mobileScale
        ? blockSize + 'px'
        : (blockSize - 5) + 'px';
    private fontSizeMedium = mobileScale
        ? (blockSize + 3) + 'px'
        : blockSize + 'px';
    private fontSizeLarge = (blockSize * 2) + 'px';

    private isUpdatingAfterGroupClear = false;
    private turnLength = 60;
    private turnCounter = 0;
    private turnSpeed = 1;
    private completedGroups: number[][][] = [];
    private clearableIDs: string[] = [];
    private comboLevel = 0;
    private debouncedResetComboLevel: () => void;
    private gameLost = false;
    private gamePaused = false;
    private handledGameLost = false;
    private gameTime = 0;
    private pasteGranted = false;


    // #region assets
    private assetsText: Record<string, Phaser.GameObjects.Text | undefined> = {};
    private assetsImage: Record<string, Phaser.GameObjects.Image | undefined> = {};
    private assetsRectangle: Record<string, Phaser.GameObjects.Rectangle | undefined> = {};

    private penguinFrame: Phaser.GameObjects.Sprite[] = [];
    private gameFrameTop: Phaser.GameObjects.Sprite[] = [];

    private teethText: Phaser.GameObjects.Text | null = null;
    private comboText: Phaser.GameObjects.Text | null = null;
    private scoreLiteralText: Phaser.GameObjects.Text | null = null;
    private scoreText: Phaser.GameObjects.Text | null = null;
    private penguinText: Phaser.GameObjects.Text | null = null;
    private penguinCustomText = false;
    private penguinRandomTextInterval: NodeJS.Timer | null = null;
    private penguinCongratulationTextTimeout: NodeJS.Timer | null = null;
    private penguin: Phaser.GameObjects.Sprite | null = null;
    // #endregion assets



    // #region finish screen
    private gameOverText: Phaser.GameObjects.Text | null = null;

    private finishScreenScoreText: Phaser.GameObjects.Text | null = null;
    private finishScreenValueText: Phaser.GameObjects.Text | null = null;

    private readingPlayerName = false;
    private playerName = '';
    private nameText: Phaser.GameObjects.Text | null = null;
    private playerNameText: Phaser.GameObjects.Text | null = null;

    private retryText: Phaser.GameObjects.Text | null = null;
    private quitGameText: Phaser.GameObjects.Text | null = null;

    private viewingHighscore = false;
    public finishScore = 0;

    private cleanMode = false;
    private restartingGame = false;
    // #endregion finish screen


    private loadedScenario: number[][] | null = null;
    private dominantClearingColor: number | undefined;
    private highscores: Highscore[] = [];

    private commandListener: Record<string, ((data: string) => void) | undefined> = {};


    // #region sounds
    private specialSound: Phaser.Sound.BaseSound | null = null;
    // #endregion sounds


    public board: Board = [];
    public score = 0;
    public collectScore = 0;
    public viewType: 'desktop' | 'mobile' = mobileScale ? 'mobile' : 'desktop';
    private playerNameInterval: NodeJS.Timer | null = null;
    // #endregion properties



    constructor() {
        super({});

        if (environment.development) {
            this.loadedScenario = scenario1;
        }

        this.publishInitialGameState();

        this.debouncedResetComboLevel = meta.debounce(() => {
            this.resetComboLevel();

            this.pasteGranted = false;
        }, COMBO_HANGING_TIME);


        this.setGameSpeedIncrease();


        this.commandsListener();


        window.addEventListener('mousedown', (event) => {
            try {
                if ((event as any).path[0].tagName === 'INPUT') {
                    return;
                }

                if (this.gameLost && !this.gamePaused) {
                    this.gamePaused = false;
                    this.publishGamePaused();
                    return;
                }


                if (event.target) {
                    const name = (event.target as any).dataset.name;
                    if (name === 'NO-PAUSE') {
                        return;
                    }

                    if (this.gamePaused && name === 'UNPAUSE-GAME') {
                        this.gamePaused = false;
                    } else if (!this.gamePaused) {
                        this.gamePaused = true;
                    }

                    this.publishGamePaused();
                }
            } catch (error) {
                return;
            }
        });

        setInterval(() => {
            if (!this.gamePaused) {
                this.gameTime += 1;
            }
        }, 1_000);
    }



    // #region lifecycle
    public preload() {
        this.cursors = this.input.keyboard.createCursorKeys();

        for (const spritesheet of Object.values(spritesheetsData)) {
            const {
                name,
                path,
                options,
            } = spritesheet;

            this.load.spritesheet(
                name,
                path,
                {
                    ...options,
                },
            );
        }

        for (const image of Object.values(imagesData)) {
            const {
                name,
                path,
            } = image;

            this.load.image(
                name,
                path,
            );
        }


        this.load.plugin('PhaserSwipe', PhaserSwipe, true);
    }

    public create() {
        // #region set background
        this.drawBackground();
        // #endregion set background


        // #region create board
        this.board = new Array(BOARD_HEIGHT);
        for (let i = 0; i < BOARD_HEIGHT; i++) {
            this.board[i] = new Array(BOARD_WIDTH);
            for (let j = 0; j < BOARD_WIDTH; j++) {
                this.board[i][j] = null;
            }
        }
        // #endregion create board


        // #region create animations
        // #region teeth
        for (const [key, name] of Object.entries(colorsNames)) {
            const RAINBOW_FRAME_RATE = 2;
            const TEETH_FRAME_RATE = 0.8;

            const frames = (colorFrames as any)[key];
            const frameRate = key === '6'
                ? RAINBOW_FRAME_RATE
                : TEETH_FRAME_RATE;

            this.anims.create({
                key: `animate-${name}`,
                frames: this.anims.generateFrameNumbers(
                    spritesheetsNames.teeth,
                    {
                        frames,
                    },
                ),
                frameRate,
                repeat: -1,
            });
        }

        for (const name of Object.values(colorsNames)) {
            const cleaningFrames = this.anims.generateFrameNumbers(
                spritesheetsNames.cleaning,
                {
                    frames: cleaningAnimationFrames,
                },
            );

            const frames = [
                ...cleaningFrames,
            ];

            this.anims.create({
                key: `animate-${name}-cleaning`,
                frames,
                frameRate: 6,
                repeat: 1,
            });
        }
        // #endregion teeth


        // #region whitening
        const xFrame = this.anims.generateFrameNumbers(
            spritesheetsNames.cleaning,
            {
                frames: [0],
            },
        );
        const whiteFrame = this.anims.generateFrameNumbers(
            spritesheetsNames.teeth,
            {
                frames: [0],
            },
        );

        const frames = [
            ...xFrame,
            ...whiteFrame,
            ...xFrame,
            ...whiteFrame,
            ...xFrame,
            ...whiteFrame,
        ];

        this.anims.create({
            key: animations.teeth.whitening,
            frames,
            frameRate: 4,
            repeat: 1,
        });
        // #endregion whitening


        // #region penguin
        const penguinIdleFrames = this.anims.generateFrameNumbers(
            spritesheetsNames.penguinIdle,
            {
                frames: [0, 1, 2, 1, 0],
            },
        );

        const penguinJumpFrames = this.anims.generateFrameNumbers(
            spritesheetsNames.penguin,
            {
                frames: [0, 1, 2, 3, 3, 3, 2, 1, 0],
            },
        );

        const penguinGameOverFrames = this.anims.generateFrameNumbers(
            spritesheetsNames.penguinGameOver,
            {
                frames: [0, 1, 2, 0, 1, 2],
            },
        );

        this.anims.create({
            key: animations.penguin.idle,
            frames: penguinIdleFrames,
            frameRate: 2.5,
            repeat: -1,
        });

        this.anims.create({
            key: animations.penguin.jump,
            frames: penguinJumpFrames,
            frameRate: 6,
            repeat: -1,
        });

        this.anims.create({
            key: animations.penguin.finish,
            frames: penguinGameOverFrames,
            frameRate: 3,
            repeat: -1,
        });
        // #endregion penguin


        // #region paste
        const pasteJiggleFrames = this.anims.generateFrameNumbers(
            spritesheetsNames.paste,
            {
                frames: [0, 1, 2, 3, 2, 1],
            },
        );
        this.anims.create({
            key: animations.paste.jiggle,
            frames: pasteJiggleFrames,
            frameRate: 2,
            repeat: -1,
        });
        // #endregion paste
        // #endregion create animations


        // #region sprites
        this.penguin = this.add.sprite(
            this.getCoordinate('x', 'penguin'),
            this.getCoordinate('y', 'penguin'),
            spritesheetsNames.penguin,
            0,
        )
        .setOrigin(0);
        if (this.viewType === 'mobile') {
            this.penguin.setDisplaySize(
                blockSize * 4.5,
                blockSize * 7,
            );
        }

        this.penguin.play(animations.penguin.idle);
        // #endregion sprites


        // #region create shapes
        this.nextShape = new Shape(
            this,
            this.loadedScenario ? this.loadedScenario[this.shapeCount + 1] : null,
        );
        this.nextShape.randomizeShape();
        this.nextShape.preview();

        this.activeShape = new Shape(
            this,
            this.loadedScenario ? this.loadedScenario[this.shapeCount] : null,
        );
        this.activeShape.randomizeShape();
        this.activeShape.activate();

        this.increaseShapeCount();

        this.publishNextShape();
        // #endregion create shapes


        this.drawFrame();

        this.input.keyboard.on('keydown', this.handleKeyDown.bind(this));


        for (const audio of Object.values(audioData)) {
            const {
                name,
                path,
            } = audio;

            this.load.audio(
                name,
                path,
            );
        }

        this.load.start();


        if (this.viewType === 'desktop') {
            this.scale.displaySize.setAspectRatio(gameBoardWidth / gameBoardHeight);
        } else {
            this.scale.displaySize.setAspectRatio(window.innerWidth / window.innerHeight);
        }
        this.scale.refresh();


        this.handleSwipe();
    }

    public update() {
        if (this.gameLost) {
            this.handleGameLost();
            return;
        }

        if (this.turnCounter >= this.turnLength) {
            if (
                this.activeShape !== null
                && this.activeShape.canMoveShape(Directions.Down)
            ) {
                this.activeShape.moveShape(Directions.Down);

                this.getCompleteGroups();
            } else {
                if (!this.activeShape) {
                    return;
                }

                if (this.activeShape.isRainbow) {
                    this.handleRainbow();
                }

                if (this.activeShape.isPaste) {
                    this.handlePaste();
                }

                if (!this.activeShape.isPaste) {
                    this.activeShape.placeShapeInBoard();
                }

                const {
                    groups,
                    ids,
                } = getCompleteGroups(this.board);
                this.completedGroups = groups;
                this.clearableIDs = ids;

                if (this.completedGroups.length > 0) {
                    this.isUpdatingAfterGroupClear = true;
                    this.clearGroups();

                    this.increaseComboLevel(this.completedGroups.length);
                } else {
                    this.promoteShapes();
                }

                this.completedGroups = [];
            }

            this.turnCounter = 0;
            return;
        }

        if (this.isUpdatingAfterGroupClear) {
            if (this.turnCounter >= this.turnLength / 10) {
                this.isUpdatingAfterGroupClear = false;
                this.promoteShapes();
                return;
            }

            if (environment.production) {
                this.turnCounter++;
            }
            return;
        }

        this.handleInput();
        if (!this.gamePaused) {
            if (environment.production) {
                this.turnCounter += this.turnSpeed;
            }
        }
    }
    // #endregion lifecycle



    // #region steps
    public previousStep() {
        if (environment.development) {
            this.turnCounter -= 59;
        }
    }

    public nextStep() {
        if (environment.development) {
            this.turnCounter += 59;
        }
    }
    // #endregion steps



    // #region game mechanics
    private setGameSpeedIncrease() {
        if (this.gameLost) {
            return;
        }

        const setTurnSpeed = (
            value: number,
        ) => {
            this.turnSpeed = value;

            this.penguinCustomText = true;
            this.drawPenguinText(
                `speed\nx${value}!`,
            );

            setTimeout(() => {
                this.penguinCustomText = false;
            }, 2_000);
        }

        const ONE_MINUTE = 60;

        setInterval(() => {
            if (this.gameLost) {
                return;
            }

            if (this.gamePaused) {
                return;
            }

            if (
                this.gameTime > ONE_MINUTE * 2
                && this.gameTime < ONE_MINUTE * 3
                && this.turnSpeed !== 2
            ) {
                setTurnSpeed(2);
            } else if (
                this.gameTime > ONE_MINUTE * 3
                && this.gameTime < ONE_MINUTE * 4
                && this.turnSpeed !== 3
            ) {
                setTurnSpeed(3);
            } else if (
                this.gameTime > ONE_MINUTE * 4
                && this.gameTime < ONE_MINUTE * 5
                && this.turnSpeed !== 4
            ) {
                setTurnSpeed(4);
            } else if (
                this.gameTime > ONE_MINUTE * 5
                && this.turnSpeed !== 5
            ) {
                setTurnSpeed(5);
            }
        }, 1_000);

        // for (const speedIncrease of speedIncreases) {
        //     const {
        //         timeout,
        //         turnSpeed,
        //     } = speedIncrease;

        //     setTimeout(() => {
        //         if (this.gameLost) {
        //             return;
        //         }

        //         this.turnSpeed = turnSpeed;

        //         this.penguinCustomText = true;
        //         this.drawPenguinText(
        //             `speed\nx${turnSpeed}!`,
        //         );

        //         setTimeout(() => {
        //             this.penguinCustomText = false;
        //         }, 2_000);
        //     }, timeout);
        // }
    }
    // #endregion game mechanics



    // #region drawing
    private computeCoordinate(
        type: 'x' | 'y',
        value: number = 0,
    ) {
        const multiplier = type === 'x'
            ? window.innerWidth
            : window.innerHeight;

        return value * multiplier / 100;
    }

    private getCoordinate(
        type: 'x' | 'y',
        kind: any,
    ) {
        const data = (imagesCoordinates[this.viewType] as any)[kind];
        const value = data[type];

        if (this.viewType === 'desktop') {
            return value;
        }

        const coordinate = this.computeCoordinate(
            type,
            value,
        );

        if (type === 'x' && data.offsetX) {
            return coordinate + data.offsetX * blockSize;
        }

        if (type === 'y' && data.offsetY) {
            return coordinate + data.offsetY * blockSize;
        }

        return coordinate;
    }

    private setScale(
        name: string,
        type = 'assetsImage',
    ) {
        if (this.viewType === 'desktop') {
            return;
        }

        const value = (imagesCoordinates['mobile'] as any)[name];
        if (!value.scale) {
            return;
        }

        (this as any)[type][name]?.setScale(
            value.scale.x,
            value.scale.y,
        );
    }

    private setItemScale(
        item: any,
        name: string,
    ) {
        if (this.viewType === 'mobile') {
            const value = (imagesCoordinates['mobile'] as any)[name];
            if (!value.scale) {
                return;
            }

            item.setScale(
                value.scale.x,
                value.scale.y,
            );
        }
    }

    private drawAssetImage(
        name: string,
        process?: (asset: any) => void,
    ) {
        this.assetsImage[name] = this.add.image(
            this.getCoordinate('x', name),
            this.getCoordinate('y', name),
            name,
        ).setOrigin(0);

        if (process) {
            process(this.assetsImage[name]);
        }

        this.setScale(name);

        return this.assetsImage[name]!;
    }

    private drawBackground() {
        this.drawGameBackgrounds();

        this.drawFrame();

        this.drawItems();
    }

    private drawGameBackgrounds() {
        const mobileCoefficient = this.viewType === 'desktop' ? 0 : 5;

        // game background
        this.assetsRectangle[imagesNames.gameBackground] = this.add.rectangle(
            this.getCoordinate('x', imagesNames.gameBackground),
            this.getCoordinate('y', imagesNames.gameBackground),
            blockSize * 12 + mobileCoefficient, blockSize * 26 + mobileCoefficient,
            hexBlackColor,
        ).setOrigin(0);

        this.drawAssetImage(
            imagesNames.mouth,
            (asset) => asset.setDisplaySize(
                blockSize * 12 + mobileCoefficient, blockSize * 8,
            ),
        );

        this.drawAssetImage(
            imagesNames.logo,
            (asset) => {
                asset.setDisplaySize(blockSize * 6.8, blockSize * 3.4);
            },
        );


        // next shape background
        this.assetsRectangle[imagesNames.nextShapeBackground] = this.add.rectangle(
            this.getCoordinate('x', imagesNames.nextShapeBackground),
            this.getCoordinate('y', imagesNames.nextShapeBackground),
            blockSize * 3.5, blockSize * 6,
            hexBlackColor,
        ).setOrigin(0);


        // penguin frame background
        const penguinFrameWidth = this.viewType === 'desktop'
            ? 170
            : blockSize * 8.5;
        const penguinFrameHeight = this.viewType === 'desktop'
            ? 90
            : blockSize * 4.5;
        this.assetsRectangle[imagesNames.penguinFrameBackground] = this.add.rectangle(
            this.getCoordinate('x', imagesNames.penguinFrameBackground),
            this.getCoordinate('y', imagesNames.penguinFrameBackground),
            penguinFrameWidth, penguinFrameHeight,
            hexBlackColor,
        ).setOrigin(0);
    }

    private drawFrame() {
        this.drawGameFrame();

        this.drawPenguinFrame();

        this.drawNextShapeFrame();
    }

    private getGameFrameData() {
        const frameName = 'frame';
        const frameIndex = {
            base: 0,
            corner: 1,
        };

        const frameDisplaySize = blockSize;

        const getLimits = () => {
            if (this.viewType === 'desktop') {
                return {
                    vertical: 25,
                    horizontal: 11,
                };
            }

            return {
                vertical: 25,
                horizontal: 11,
            };
        }

        const limits = getLimits();
        const verticalLimit = limits.vertical;
        const horizontalLimit = limits.horizontal;

        const getCoordinates = () => {
            if (this.viewType === 'desktop') {
                return {
                    x: 0,
                    y: 0,
                };
            }

            const frameBackground = imagesCoordinates.mobile['game-background'];
            const x = frameBackground.offsetX * blockSize;
            const y = frameBackground.offsetY * blockSize;

            return {
                x: Math.ceil(x) - frameDisplaySize + 5,
                y: Math.ceil(y) - frameDisplaySize + 5,
            };
        }

        const coordinates = getCoordinates();
        const topLeftX = coordinates.x;
        const topLeftY = coordinates.y;

        const verticalLength = verticalLimit * frameDisplaySize + frameDisplaySize * 3;
        const horizontalLength = horizontalLimit * frameDisplaySize + frameDisplaySize * 3;

        return {
            frameName,
            frameIndex,
            verticalLimit,
            horizontalLimit,
            topLeftX,
            topLeftY,
            frameDisplaySize,
            verticalLength,
            horizontalLength,
        };
    }

    private drawGameFrameTop() {
        for (const sprite of this.gameFrameTop) {
            sprite.destroy();
        }

        const {
            frameName,
            frameIndex,
            horizontalLimit,
            topLeftX,
            topLeftY,
            frameDisplaySize,
        } = this.getGameFrameData();

        for (let i = 0; i <= horizontalLimit; i++) {
            const sprite = this.add.sprite(
                topLeftX + frameDisplaySize + frameDisplaySize * i,
                topLeftY,
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(frameDisplaySize, frameDisplaySize);

            this.gameFrameTop.push(sprite);
        }
    }

    private redrawGameFrameTop() {
        for (const sprite of this.gameFrameTop) {
            sprite.destroy();
        }

        this.gameFrameTop = [];

        this.drawGameFrameTop();
    }

    private drawGameFrame() {
        const gameFrame = [];

        const {
            frameName,
            frameIndex,
            horizontalLimit,
            verticalLimit,
            horizontalLength,
            verticalLength,
            topLeftX,
            topLeftY,
            frameDisplaySize,
        } = this.getGameFrameData();

        // #region corners
        const frame1 = this.add.sprite(
            topLeftX + CORNER_ADJUSTMENT, topLeftY,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[0]);
        gameFrame.push(frame1);

        const frame2 = this.add.sprite(
            topLeftX + horizontalLength,
            topLeftY + CORNER_ADJUSTMENT,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[90]);
        gameFrame.push(frame2);

        const frame3 = this.add.sprite(
            topLeftX + horizontalLength - CORNER_ADJUSTMENT,
            topLeftY + verticalLength,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[180]);
        gameFrame.push(frame3);

        const frame4 = this.add.sprite(
            topLeftX,
            topLeftY + verticalLength - CORNER_ADJUSTMENT,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[270]);
        gameFrame.push(frame4);
        // #endregion corners


        // vertical frame
        for (let i = 0; i <= verticalLimit; i++) {
            const frame = this.add.sprite(
                topLeftX + frameDisplaySize,
                topLeftY + frameDisplaySize + frameDisplaySize * i,
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(frameDisplaySize, frameDisplaySize)
            .setRotation(degrees[90]);

            gameFrame.push(frame);
        }

        for (let i = 0; i <= verticalLimit; i++) {
            const frame = this.add.sprite(
                topLeftX + horizontalLength,
                topLeftY + frameDisplaySize + frameDisplaySize * i,
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(frameDisplaySize, frameDisplaySize)
            .setRotation(degrees[90]);

            gameFrame.push(frame);
        }


        // horizontal frame
        this.drawGameFrameTop();

        for (let i = 0; i <= horizontalLimit; i++) {
            const frame = this.add.sprite(
                topLeftX + frameDisplaySize + frameDisplaySize * i,
                topLeftY + verticalLength - frameDisplaySize,
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(frameDisplaySize, frameDisplaySize);

            gameFrame.push(frame);
        }
    }

    private drawPenguinFrame() {
        const framePenguin = 'frame-penguin';
        const penguiFrameDisplaySize = {
            base: 10,
            corner: 7,
        };
        const penguinFrameIndex = {
            base: 0,
            corner: 1,
        };

        const getLimits = () => {
            if (this.viewType === 'desktop') {
                return {
                    vertical: 8,
                    horizontal: 16,
                };
            }

            if (window.innerWidth < mobileWidthSteps.mobileA) {
                return {
                    vertical: 4,
                    horizontal: 9,
                };
            }

            if (window.innerWidth < mobileWidthSteps.mobileB) {
                return {
                    vertical: 5,
                    horizontal: 11,
                };
            }

            if (window.innerWidth < mobileWidthSteps.mobileC) {
                return {
                    vertical: 6,
                    horizontal: 12,
                };
            }

            return {
                vertical: 7,
                horizontal: 16,
            };
        }

        const limits = getLimits();
        const verticalLimit = limits.vertical;
        const horizontalLimit = limits.horizontal;

        const getCoordinates = () => {
            if (this.viewType === 'desktop') {
                return {
                    x: 400,
                    y: 325,
                };
            }

            const penguinFrameBackground = imagesCoordinates.mobile['penguin-frame-background'];
            const x = penguinFrameBackground.offsetX * blockSize;
            const y = penguinFrameBackground.offsetY * blockSize;

            return {
                x: Math.ceil(x) - 25,
                y: Math.ceil(y),
            };
        }

        const coordinates = getCoordinates();
        const topLeftX = coordinates.x;
        const topLeftY = coordinates.y;

        const verticalLength = verticalLimit * 10 - 5;
        const horizontalLength = horizontalLimit * 10 + 15;


        // #region corners
        {
            const sprite = this.add.sprite(
                topLeftX + 15,
                topLeftY,
                framePenguin,
                penguinFrameIndex.corner,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.corner, penguiFrameDisplaySize.base)
            .setRotation(degrees[270]);
            this.penguinFrame.push(sprite);
        }

        {
            const sprite = this.add.sprite(
                // topLeftX + 15 + 175 + 5,
                topLeftX + 15 + horizontalLength + 5,
                topLeftY - 10,
                framePenguin,
                penguinFrameIndex.corner,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.corner, penguiFrameDisplaySize.base)
            .setRotation(degrees[0]);
            this.penguinFrame.push(sprite);
        }

        {
            const sprite = this.add.sprite(
                topLeftX + 10 + 15,
                topLeftY + verticalLength + 15 + 10,
                // topLeftY + 75 + 15 + 10,
                framePenguin,
                penguinFrameIndex.corner,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.corner, penguiFrameDisplaySize.base)
            .setRotation(degrees[180]);
            this.penguinFrame.push(sprite);
        }

        {
            const sprite = this.add.sprite(
                // topLeftX + 10 + 175 + 15 + 5,
                topLeftX + 10 + horizontalLength + 15 + 5,
                // topLeftY + 75 + 15,
                topLeftY + verticalLength + 15,
                framePenguin,
                penguinFrameIndex.corner,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.corner, penguiFrameDisplaySize.base)
            .setRotation(degrees[90]);
            this.penguinFrame.push(sprite);
        }
        // #endregion corners


        // #region vertical
        for (let i = 0; i <= verticalLimit; i++) {
            const sprite = this.add.sprite(
                topLeftX + 25 - 10,
                topLeftY + 10 + 10 * i,
                framePenguin,
                penguinFrameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.base, penguiFrameDisplaySize.base)
            .setRotation(degrees[270]);
            this.penguinFrame.push(sprite);
        }


        for (let i = 0; i <= verticalLimit; i++) {
            const sprite = this.add.sprite(
                // topLeftX + 25 + 175 + 5,
                topLeftX + 25 + horizontalLength + 5,
                topLeftY + 10 * i,
                framePenguin,
                penguinFrameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.base, penguiFrameDisplaySize.base)
            .setRotation(degrees[90]);
            this.penguinFrame.push(sprite);
        }
        // #endregion vertical


        // #region horizontal
        for (let i = 0; i <= horizontalLimit; i++) {
            const sprite = this.add.sprite(
                topLeftX + 25 + 10 * i,
                topLeftY - 10,
                framePenguin,
                penguinFrameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.base, penguiFrameDisplaySize.base)
            this.penguinFrame.push(sprite);
        }

        for (let i = 0; i <= horizontalLimit; i++) {
            const sprite = this.add.sprite(
                topLeftX + 25 + 10 + 10 * i,
                // topLeftY + 15 + 10 + 75,
                topLeftY + 15 + 10 + verticalLength,
                framePenguin,
                penguinFrameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(penguiFrameDisplaySize.base, penguiFrameDisplaySize.base)
            .setRotation(degrees[180]);
            this.penguinFrame.push(sprite);
        }
        // #endregion horizontal
    }

    private drawNextShapeFrame() {
        const {
            frameName,
            frameIndex,
            frameDisplaySize,
            topLeftX,
            topLeftY,
            horizontalLength,
        } = this.getGameFrameData();

        const getLimits = () => {
            if (this.viewType === 'desktop') {
                return {
                    vertical: 5,
                    horizontal: 1,
                };
            }

            return {
                vertical: 5,
                horizontal: 1,
            };
        }

        const limits = getLimits();
        const verticalLimit = limits.vertical;
        const horizontalLimit = limits.horizontal;


        // #region corners
        this.add.sprite(
            topLeftX + horizontalLength + frameDisplaySize - CORNER_ADJUSTMENT,
            topLeftY + frameDisplaySize * 3,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[180]);

        this.add.sprite(
            topLeftX + horizontalLength + CORNER_ADJUSTMENT,
            topLeftY + frameDisplaySize,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[0]);

        this.add.sprite(
            topLeftX + horizontalLength + frameDisplaySize * (horizontalLimit + 3),
            topLeftY + frameDisplaySize + CORNER_ADJUSTMENT,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[90]);

        this.add.sprite(
            topLeftX + horizontalLength + frameDisplaySize * (horizontalLimit + 3) - CORNER_ADJUSTMENT,
            topLeftY + frameDisplaySize + frameDisplaySize * (verticalLimit + 3),
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[180]);

        this.add.sprite(
            topLeftX + horizontalLength,
            topLeftY + frameDisplaySize + frameDisplaySize * (verticalLimit + 3) - CORNER_ADJUSTMENT,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[270]);

        this.add.sprite(
            topLeftX + horizontalLength + frameDisplaySize,
            topLeftY + frameDisplaySize + frameDisplaySize * (verticalLimit + 1) + CORNER_ADJUSTMENT,
            frameName,
            frameIndex.corner,
        )
        .setOrigin(0)
        .setDisplaySize(frameDisplaySize, frameDisplaySize)
        .setRotation(degrees[90]);
        // #endregion corners


        // #region horizontal
        for (let i = 0; i <= horizontalLimit; i++) {
            this.add.sprite(
                topLeftX + horizontalLength + frameDisplaySize + frameDisplaySize * i,
                topLeftY + frameDisplaySize,
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(frameDisplaySize, frameDisplaySize);
        }

        for (let i = 0; i <= horizontalLimit; i++) {
            this.add.sprite(
                topLeftX + horizontalLength + frameDisplaySize + frameDisplaySize * i,
                topLeftY + frameDisplaySize + frameDisplaySize * (verticalLimit + 2),
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setDisplaySize(frameDisplaySize, frameDisplaySize);
        }
        // #endregion horizontal


        // #region vertical
        for (let i = 0; i <= verticalLimit; i++) {
            this.add.sprite(
                topLeftX + horizontalLength + frameDisplaySize * (horizontalLimit + 2) + frameDisplaySize,
                topLeftY + frameDisplaySize * 2 + frameDisplaySize * i,
                frameName,
                frameIndex.base,
            )
            .setOrigin(0)
            .setRotation(degrees[90])
            .setDisplaySize(frameDisplaySize, frameDisplaySize);
        }
        // #endregion vertical
    }

    private drawItems() {
        this.drawAssetImage(
            imagesNames.scoreTooth,
            (asset) => {
                if (this.viewType === 'mobile') {
                    asset.setDisplaySize(blockSize * 2, blockSize * 2);
                }
            }
        );
        this.drawTeethCount();

        this.drawAssetImage(
            imagesNames.toothpaste,
            (
                asset,
            ) => {
                if (this.viewType === 'mobile') {
                    asset.setDisplaySize(blockSize * 9, blockSize * 4.5);
                }
            },
        );
        this.drawScore();

        this.drawAssetImage(
            imagesNames.brush,
            (
                asset,
            ) => {
                if (this.viewType === 'mobile') {
                    asset.setDisplaySize(blockSize * 15, blockSize * 2);
                    asset.setRotation(degrees[270]);
                }
            },
        );

        this.drawBubbles();

        this.drawPenguinText(initialMessage);

        this.penguinRandomTextInterval = setInterval(() => {
            this.drawPenguinRandomText();
        }, randomMessageInterval);
    }

    private drawTeethCount(
        color = 'black',
    ) {
        if (this.teethText) {
            this.teethText.destroy();
        }

        this.teethText = this.add.text(
            this.getCoordinate('x', 'teethText'),
            this.getCoordinate('y', 'teethText'),
            'X' + this.teethCount,
            {
                color,
                fontSize: this.fontSize,
                fontFamily: 'Gamefont',
            },
        );
    }

    private drawScore() {
        if (this.scoreLiteralText) {
            this.scoreLiteralText.destroy();
        }

        if (this.scoreText) {
            this.scoreText.destroy();
        }

        this.scoreLiteralText = this.add.text(
            this.getCoordinate('x', 'scoreLiteralText'),
            this.getCoordinate('y', 'scoreLiteralText'),
            'Score',
            {
                color: 'black',
                fontSize: this.fontSize,
                fontFamily: 'Gamefont',
            },
        );
        this.setItemScale(
            this.scoreLiteralText,
            'scoreLiteralText',
        );

        this.scoreText = this.add.text(
            this.getCoordinate('x', 'scoreText'),
            this.getCoordinate('y', 'scoreText'),
            displayScore(this.score),
            {
                color: 'black',
                fontSize: this.fontSize,
                fontFamily: 'Gamefont',
            },
        );
        this.setItemScale(
            this.scoreText,
            'scoreText',
        );
    }

    /**
     * `index` of the bubble (0 to 4)
     *
     * `kind` of the bubble (large or small, 0 or 1)
     *
     * @param index
     * @param kind
     */
    private drawBubble(
        index: number,
        kind: number,
    ) {
        this.assetsImage[bubbles[index]] = this.add.sprite(
            this.getCoordinate('x', bubbles[index]),
            this.getCoordinate('y', bubbles[index]),
            'bubbles',
            kind,
        ).setOrigin(0)
        .setDisplaySize(blockSize * 2, blockSize * 2);
    }

    private drawBubbles() {
        this.drawBubble(0, 0);
        this.drawBubble(1, 0);
        this.drawBubble(2, 0);

        this.drawBubble(3, 1);
        this.drawBubble(4, 1);
    }

    private drawPenguinText(
        value: string,
        style?: any,
    ) {
        if (this.cleanMode) {
            return;
        }

        this.penguinText?.destroy();

        this.penguinText = this.add.text(
            this.getCoordinate('x', 'penguinText'),
            this.getCoordinate('y', 'penguinText'),
            value,
            {
                ...messageDefaultStyle,
                ...style,
                fontSize: style?.fontSize === '50px'
                    ? this.fontSizeLarge
                    : this.fontSize,
            },
        );
    }

    private drawPenguinRandomText() {
        if (this.gameLost) {
            return;
        }
        if (this.penguinCustomText) {
            return;
        }
        if (this.cleanMode) {
            return;
        }

        const message = getRandomMessage(randomMessages);

        this.drawPenguinText(
            message,
            messageRandomStyle,
        );
    }

    private drawPenguinScoreTextCall() {
        if (this.cleanMode) {
            return;
        }

        if (!this.collectScore) {
            return;
        }

        this.penguinCustomText = true;

        const getColorValueFromDominant = () => {
            const defaultColor = 'white';

            if (typeof this.dominantClearingColor === 'undefined') {
                return defaultColor;
            }

            // FORCED
            const colorName = (colorsNames as any)[this.dominantClearingColor];
            if (!colorName) {
                return defaultColor;
            }
            const colorValue = (colors as any)[colorName];
            if (!colorValue) {
                return defaultColor;
            }

            return colorValue;
        }
        const color = getColorValueFromDominant();

        this.drawPenguinText(
            this.collectScore + '',
            {
                ...messageScoreStyle,
                color,
            },
        );
        this.collectScore = 0;

        this.drawPenguinCongratulation();
    }
    private drawPenguinScoreText = meta.debouncedCallback(
        this.drawPenguinScoreTextCall.bind(this),
        1_000,
    );

    private drawPenguinCongratulationCall() {
        this.penguinCongratulationTextTimeout = setTimeout(() => {
            if (this.gameLost) {
                return;
            }
            if (this.cleanMode) {
                return;
            }

            this.penguinCustomText = true;
            const message = getRandomMessage(congratulationMessages);

            this.drawPenguinText(
                message,
            );

            setTimeout(() => {
                this.penguinCustomText = false;
            }, congratulationMessageDuration);
        }, scoreMessageDuration);
    }
    private drawPenguinCongratulation = meta.debouncedCallback(
        this.drawPenguinCongratulationCall.bind(this),
        1_000,
    );

    private drawPenguinFinishText() {
        if (this.penguinRandomTextInterval) {
            clearInterval(this.penguinRandomTextInterval);
        }
        if (this.penguinCongratulationTextTimeout) {
            clearTimeout(this.penguinCongratulationTextTimeout);
        }

        setTimeout(() => {
            if (this.cleanMode) {
                return;
            }

            this.penguinCustomText = true;
            const message = getRandomMessage(finishMessages);

            this.drawPenguinText(
                message,
                messageRandomStyle,
            );
        }, 900);
    }
    // #endregion drawing



    // #region animations
    private penguinAnimateScoreCall() {
        if (this.gameLost) {
            return;
        }

        if (!this.penguin) {
            return;
        }

        this.penguin.stop();
        this.penguin.play(animations.penguin.jump);

        setTimeout(() => {
            if (!this.penguin) {
                return;
            }

            this.penguin.stop();
            this.penguin.play(animations.penguin.idle);
        }, 1_500);
    }
    private penguinAnimateScore = meta.debouncedCallback(
        this.penguinAnimateScoreCall.bind(this),
        100,
    );

    private penguinAnimateFinish() {
        if (!this.penguin) {
            return;
        }

        this.penguin.stop();
        this.penguin.play(animations.penguin.finish);

        // Rerun animation.
        setTimeout(() => {
            if (!this.penguin) {
                return;
            }

            this.penguin.stop();
            this.penguin.play(animations.penguin.finish);
        }, 2_000);
    }
    // #endregion animations



    // #region input
    private handleInput() {
        if (!this.cursors) {
            return;
        }

        if (!this.activeShape) {
            return;
        }


        if (this.activeShape.isTweening) {
            this.activeShape.updateTween();
            return;
        }

        if (this.cursors.up.isDown) {
            this.inputFlipColors();
            return;
        }

        if (this.cursors.left.isDown) {
            this.inputMoveShapeLeft();
            return;
        }

        if (this.cursors.right.isDown) {
            this.inputMoveShapeRight();
            return;
        }

        if (this.cursors.down.isDown) {
            this.inputMoveShapeDown();
            return;
        }
    }

    private handleSwipe() {
        if (this.viewType === 'mobile') {
            const swipe: any = this.plugins.get('PhaserSwipe');
            swipe.load(this);

            this.events.on('swipe', (event: any) => {
                if (event.right) {
                    this.inputMoveShapeRight();
                } else if (event.left) {
                    this.inputMoveShapeLeft();
                } else if (event.up) {
                    this.inputFlipColors();
                } else if (event.down) {
                    for (let i = 0; i < 3; i++) {
                        setTimeout(() => {
                            this.inputMoveShapeDown();
                        }, 60 * i);
                    }
                }
            });
        }
    }

    private inputFlipColors() {
        if (!this.activeShape) {
            return;
        }

        if (this.activeShape.isTweening) {
            this.activeShape.updateTween();
            return;
        }

        if (this.activeShape.canFlipColors()) {
            this.playSound(audioNames.colorChange);

            this.activeShape.flipColors();
            this.activeShape.isTweening = true;

            this.drawGameFrame();
        }
    }

    private inputMoveShapeLeft() {
        if (!this.activeShape) {
            return;
        }

        if (this.activeShape.isTweening) {
            this.activeShape.updateTween();
            return;
        }

        if (this.activeShape.canMoveShape(Directions.Left)) {
            this.activeShape.moveShape(Directions.Left);
            this.activeShape.isTweening = true;
        }
    }

    private inputMoveShapeRight() {
        if (!this.activeShape) {
            return;
        }

        if (this.activeShape.isTweening) {
            this.activeShape.updateTween();
            return;
        }

        if (this.activeShape.canMoveShape(Directions.Right)) {
            this.activeShape.moveShape(Directions.Right);
            this.activeShape.isTweening = true;
        }
    }

    private inputMoveShapeDown() {
        if (!this.activeShape) {
            return;
        }

        if (this.activeShape.isTweening) {
            this.activeShape.updateTween();
            return;
        }

        this.turnCounter += this.turnLength / 5;
    }

    private commandsListener() {
        this.on('command', (data) => {
            if (
                !data
                || typeof data !== 'string'
            ) {
                return;
            }

            switch (data.toLowerCase()) {
                case 'd':
                case 'down':
                    // HACK force turn counter
                    for (let i = 0; i < 5; i++) {
                        this.inputMoveShapeDown();
                    }
                    break;
                case 'l':
                case 'left':
                    this.inputMoveShapeLeft();
                    break;
                case 'r':
                case 'right':
                    this.inputMoveShapeRight();
                    break;
                case 'c':
                case 'change':
                case 'f':
                case 'flip':
                    this.inputFlipColors();
                    break;
            }
        });
    }

    private addMobileInput() {
        const input = document.createElement('input');
        input.style.position = 'absolute';
        input.style.top = '0';
        input.style.bottom = '0';
        input.style.left = '0';
        input.style.right = '0';
        input.style.opacity = '0';
        input.style.zIndex = '99999';
        input.addEventListener('change', (
            event,
        ) => {
            const value = (event.target as any).value;

            const playerNameDrawValue = displayNameField(value);
            this.playerNameText?.destroy();
            this.playerNameText = this.add.text(
                95,
                390,
                playerNameDrawValue,
                messageRandomStyle,
            );
        });
        document.body.appendChild(input);
        input.focus();
    }

    private on(
        command: string,
        callback: (data: string) => void,
    ) {
        this.commandListener[command] = callback;
    }

    public emit(
        data: string,
    ) {
        try {
            const callback = this.commandListener['command'];
            if (!callback) {
                return;
            }

            callback(data);
        } catch (error) {
            return;
        }
    }
    // #endregion input



    // #region shape
    private increaseShapeCount() {
        this.shapeCount += 1;
    }

    private promoteShapes() {
        if (!this.nextShape) {
            return;
        }

        if (this.checkGameLost()) {
            return;
        }

        this.activeShape = null;

        this.nextShape.clearPreview();
        this.activeShape = this.nextShape;
        this.activeShape.activate();

        this.nextShape = new Shape(
            this,
            this.loadedScenario ? this.loadedScenario[this.shapeCount + 1] : null,
        );
        this.nextShape.randomizeShape();
        this.nextShape.preview();

        if (Math.random() < COMBO_RAINBOW_CHANCE) {
            this.comboRainbow();
        }

        this.increaseShapeCount();
        this.publishNextShape();
        this.redrawGameFrameTop();

        if (this.activeShape.isRainbow || this.activeShape.isPaste) {
            this.playSpecialSound();
        }
    }
    // #endregion shape



    // #region combos
    private comboRainbow() {
        if (!this.nextShape) {
            return;
        }

        this.nextShape.toRainbow();
    }

    private handleRainbow() {
        if (!this.activeShape) {
            return;
        }

        this.stopSpecialSound();

        const lastBlockCoords = this.activeShape.getLastBlockCoords();

        if (lastBlockCoords) {
            const selectedColor = this.getSelectedColor(lastBlockCoords);

            if (typeof selectedColor === 'number') {
                this.clearSelectedColor(selectedColor);
            }
        }
    }

    private comboPaste() {
        if (this.gameLost) {
            return;
        }

        if (!this.nextShape) {
            return;
        }

        this.nextShape.toPaste();
    }

    private handlePaste() {
        if (!this.activeShape) {
            return;
        }

        this.stopSpecialSound();

        const lastBlockCoords = this.activeShape.getLastBlockCoords();

        this.activeShape.destroyPaste();

        if (lastBlockCoords) {
            const selectedColor = this.getSelectedColor(lastBlockCoords);

            if (typeof selectedColor === 'number') {
                this.whitenSelectedColor(selectedColor);
            }
        }
    }
    // #endregion combos



    // #region finish screen
    private drawFinishScreen() {
        this.playEndgameSound();

        this.queryHighscores();


        if (this.assetsImage[imagesNames.logo]) {
            this.assetsImage[imagesNames.logo]?.destroy();
            this.assetsImage[imagesNames.logo] = this.add.image(
                this.getCoordinate('x', imagesNames.logo),
                this.getCoordinate('y', imagesNames.logo),
                'logo-white',
            ).setOrigin(0)
            .setDisplaySize(blockSize * 6.8, blockSize * 3.4);
        }

        this.drawTeethCount('white');


        for (const row of this.board) {
            for (const block of row) {
                if (!block) {
                    continue;
                }

                block.destroy();
            }
        }

        this.board = [];

        const score = this.score;
        this.finishScore = score;
        this.score = 0;


        const writeFinishScreenTexts = () => {
            setTimeout(() => {
                /*eslint no-lone-blocks: 0*/

                const textStyle = {
                    ...messageRandomStyle,
                    fontSize: this.fontSize,
                };


                // GAME OVER
                {
                    this.gameOverText = this.add.text(
                        this.getCoordinate('x', 'gameOver'),
                        this.getCoordinate('y', 'gameOver'),
                        'GAME\nOVER',
                        textStyle,
                    );
                }


                // Score
                {
                    this.finishScreenScoreText = this.add.text(
                        this.getCoordinate('x', 'finishScreenScoreText'),
                        this.getCoordinate('y', 'finishScreenScoreText'),
                        'Score',
                        {
                            ...textStyle,
                            fontSize: this.fontSizeMedium,
                        },
                    );
                    this.finishScreenValueText = this.add.text(
                        this.getCoordinate('x', 'finishScreenValueText'),
                        this.getCoordinate('y', 'finishScreenValueText'),
                        displayScore(score),
                        {
                            ...textStyle,
                            fontSize: this.fontSizeMedium,
                        },
                    );
                }


                // Name
                {
                    this.readingPlayerName = true;
                    this.nameText = this.add.text(
                        this.getCoordinate('x', 'nameText'),
                        this.getCoordinate('y', 'nameText'),
                        'NAME',
                        textStyle,
                    );
                    this.nameText.setInteractive({
                        useHandCursor: true,
                    });
                    this.nameText.on('pointerdown', () => {
                        if (this.playerName.length < 1) {
                            return;
                        }

                        this.drawHighscore();
                    });

                    this.playerNameText = this.add.text(
                        this.getCoordinate('x', 'playerNameText'),
                        this.getCoordinate('y', 'playerNameText'),
                        displayNameField(''),
                        textStyle,
                    );

                    let flicker = false;

                    this.playerNameInterval = setInterval(() => {
                        if (this.restartingGame) {
                            return;
                        }

                        if (!this.readingPlayerName) {
                            return;
                        }

                        this.playerNameText?.destroy();
                        this.playerNameText = this.add.text(
                            this.getCoordinate('x', 'playerNameText'),
                            this.getCoordinate('y', 'playerNameText'),
                            displayNameFlicker(this.playerName, flicker),
                            textStyle,
                        );

                        flicker = !flicker;
                    }, 600);
                }


                // RETRY
                {
                    this.retryText = this.add.text(
                        this.getCoordinate('x', 'retryText'),
                        this.getCoordinate('y', 'retryText'),
                        'RETRY',
                        {
                            ...textStyle,
                            color: '#8b9296',
                        },
                    );
                    this.retryText.setInteractive({
                        useHandCursor: true,
                    });
                    this.retryText.on('pointerout', () => {
                        if (!this.retryText) {
                            return;
                        }

                        this.retryText.setStyle({
                            color: '#8b9296',
                        });
                    });
                    this.retryText.on('pointerover', () => {
                        if (!this.retryText) {
                            return;
                        }

                        this.retryText.setStyle({
                            color: '#008ffd',
                        });
                    });
                    this.retryText.on('pointerdown', () => {
                        this.restartGame();
                    });
                }


                // QUIT GAME
                {
                    this.quitGameText = this.add.text(
                        this.getCoordinate('x', 'quitGameText'),
                        this.getCoordinate('y', 'quitGameText'),
                        'QUIT GAME',
                        {
                            ...textStyle,
                            color: '#8b9296',
                        },
                    );
                    this.quitGameText.setInteractive({
                        useHandCursor: true,
                    });
                    this.quitGameText.on('pointerout', () => {
                        if (!this.quitGameText) {
                            return;
                        }

                        this.quitGameText.setStyle({
                            color: '#8b9296',
                        });
                    });
                    this.quitGameText.on('pointerover', () => {
                        if (!this.quitGameText) {
                            return;
                        }

                        this.quitGameText.setStyle({
                            color: '#008ffd',
                        });
                    });
                    this.quitGameText.on('pointerdown', () => {
                        this.quitGame();
                    });
                }
            }, 700);
        }

        const generateWhiteTeethBoard = () => {
            this.nextShape?.cleaningPreview();

            this.board = new Array(BOARD_HEIGHT);
            for (let i = 0; i < BOARD_HEIGHT; i++) {
                this.board[i] = new Array(BOARD_WIDTH);
                for (let j = 0; j < BOARD_WIDTH; j++) {
                    if (i === 0 || i === 1) {
                        this.board[i][j] = null;
                        continue;
                    }

                    const color = 0;
                    const animate = false;
                    const asset = 'cleaning';
                    const block = new Block(this, color);
                    block.makeBlock(
                        j, i,
                        color,
                        asset,
                        animate,
                    );
                    this.board[i][j] = block;
                }
            }
        }

        const cleanSpecificBoard = () => {
            const clearRows = [2, 3, 4, 5, 6, 7, 8, 9, 11, 13];
            const clearColumns = [1, 2, 3, 4];

            setTimeout(() => {
                this.cleanSpecificBoard(
                    clearRows,
                    clearColumns,
                );

                writeFinishScreenTexts();
            }, 500);
        }


        generateWhiteTeethBoard();
        cleanSpecificBoard();
    }

    private destroyPlayerNameText() {
        this.playerNameText?.destroy();
        if (this.playerNameInterval) {
            clearInterval(this.playerNameInterval);
        }

        setTimeout(() => {
            this.playerNameText?.destroy();
        }, 100);
        setTimeout(() => {
            this.playerNameText?.destroy();
        }, 700);
    }

    private drawHighscore() {
        const highscore: Highscore = {
            name: this.playerName,
            score: this.finishScore,
        };
        saveHighscore(highscore);

        /**
         * Similar logic as the one happening on the server-side
         * in case the current game breaks into the high scores.
         *
         * @param highscore
         * @returns
         */
        const updateHighscores = (
            highscore: Highscore,
        ) => {
            if (this.highscores.length === 0) {
                this.highscores = [
                    highscore,
                ];
            }


            let index;
            for (const [idx, currentHighscore] of this.highscores.entries()) {
                if (highscore.score > currentHighscore.score) {
                    index = idx;
                    break;
                }
            }

            if (typeof index === 'undefined') {
                return;
            }


            const highscores: Highscore[] = [
                ...this.highscores,
            ];

            highscores.splice(index, 0, highscore);

            this.highscores = highscores.slice(0, 10);
        }
        updateHighscores(highscore);


        this.readingPlayerName = false;
        this.viewingHighscore = true;

        const twoHalfSpaces = '  '; // (U+2009)
        this.gameOverText?.setText(twoHalfSpaces + 'HIGH\nSCORE');

        this.nameText?.destroy();
        this.destroyPlayerNameText();

        this.finishScreenScoreText?.destroy();
        this.finishScreenValueText?.destroy();


        const cleanMargins = () => {
            const clearRows = [2, 3, 4, 5, 6, 7, 8, 9];
            const clearColumns = [0, 5];

            this.cleanSpecificBoard(
                clearRows,
                clearColumns,
            );
        }
        cleanMargins();


        const transformBlocksToRainbow = () => {
            this.nextShape?.rainbowPreview();

            for (let i = 0; i < BOARD_HEIGHT; i++) {
                for (let j = 0; j < BOARD_WIDTH; j++) {
                    const block = this.board[i][j];
                    if (!block) {
                        continue;
                    }

                    block.recolorBlock(6);
                }
            }
        }

        const drawHighscore = async () => {
            // integrateCurrentHighscoreIntoHighscores
            const highscores = padHighscores(this.highscores);

            for (const [index, highscore] of highscores.entries()) {
                const {
                    name,
                    score,
                } = highscore;

                const yCoefficient = mobileScale ? 1 : 5;
                const yPosition = this.getCoordinate('y', 'highscoreGeneral') + (blockSize + yCoefficient) * index;

                const style = {
                    ...messageRandomStyle,
                    fontSize: mobileScale
                        ? '10px'
                        : '15px',
                };

                this.add.text(
                    this.getCoordinate('x', 'highscoreIndex'),
                    yPosition,
                    (index + 1) + '.',
                    style,
                );

                this.add.text(
                    this.getCoordinate('x', 'highscoreName'),
                    yPosition,
                    name,
                    style,
                );

                this.add.text(
                    this.getCoordinate('x', 'highscoreScore'),
                    yPosition,
                    displayScore(score),
                    style,
                );
            }
        }


        setTimeout(() => {
            transformBlocksToRainbow();

            drawHighscore();
        }, 700);
    }
    // #endregion finish screen



    // #region clearing
    private getCompleteGroups() {
        if (this.gameLost) {
            return;
        }

        const {
            groups,
            ids,
        } = getCompleteGroups(this.board);

        this.completedGroups = groups;
        this.clearableIDs = ids;

        if (this.completedGroups.length > 0) {
            this.increaseComboLevel(this.completedGroups.length);

            this.clearGroups();
            this.completedGroups = [];
        }
    }

    private clearGroups() {
        if (this.gameLost) {
            return;
        }

        const {
            board,
            affectedColumns,
            colors,
        } = clearGroups(
            this.completedGroups,
            this.clearableIDs,
            this.board,
            this.increaseScore.bind(this),
        );
        this.board = board;


        this.setDominantClearingColor(
            colors,
        );

        this.reclearGroups(
            affectedColumns,
        );
    }

    private reclearGroups(
        affectedColumns: Set<number>,
    ) {
        if (this.gameLost) {
            return;
        }

        if (affectedColumns.size) {
            setTimeout(() => {
                if (this.gameLost) {
                    return;
                }

                const handled = new Map<string, boolean>();

                const drop = (
                    row: number,
                    column: number,
                ) => {
                    const block = this.board[row][column];
                    if (block) {
                        return;
                    }

                    const nextBlockQuery = findNextBlockInColumn(
                        row,
                        column,
                        this.clearableIDs,
                        this.board,
                    );

                    if (nextBlockQuery) {
                        const blockHandledKey = `${nextBlockQuery.row}-${nextBlockQuery.column}`;
                        if (handled.has(blockHandledKey)) {
                            return;
                        }

                        handled.set(blockHandledKey, true);

                        const nextBlock = this.board[nextBlockQuery.row][nextBlockQuery.column];
                        if (nextBlock) {
                            nextBlock.moveBlock(column, row);
                            this.board[row][column] = nextBlock;
                            this.board[nextBlockQuery.row][nextBlockQuery.column] = null;
                        }
                    }
                }

                for (const column of affectedColumns) {
                    for (
                        let row = this.board.length - 1;
                        row >= 0;
                        row--
                    ) {
                        drop(
                            row,
                            column,
                        );
                    }
                }

                this.clearableIDs = [];
                this.completedGroups = [];

                this.getCompleteGroups();
            }, DROPPING_TIME);
        }
    }

    private getSelectedColor(
        lastBlockCoords: {
            x: number,
            y: number,
        },
    ) {
        const underRow = this.board[lastBlockCoords.y + 1];
        if (!underRow) {
            return;
        }

        const underBlock = underRow[lastBlockCoords.x];
        if (!underBlock) {
            return;
        }

        return underBlock.color;
    }

    private clearSelectedColor(
        color: number,
    ) {
        if (this.gameLost) {
            return;
        }

        const affectedColumns = new Set<number>();
        let scoredBlocks = 0;
        let blockValue = 0;

        for (const [rowNumber, row] of this.board.entries()) {
            for (const [column, block] of row.entries()) {
                if (!block) {
                    continue;
                }

                if (block.color === color) {
                    affectedColumns.add(column);
                    block.clean();
                    this.board[rowNumber][column] = null;

                    scoredBlocks += 1;
                    blockValue = getBlockScoreValue(block.color);
                }
            }
        }

        this.increaseScore(
            scoredBlocks,
            blockValue,
        );

        this.reclearGroups(affectedColumns);
    }

    private whitenSelectedColor(
        color: number,
    ) {
        if (this.gameLost) {
            return;
        }

        for (const row of this.board) {
            for (const block of row) {
                if (!block) {
                    continue;
                }

                if (block.color === color) {
                    block.whitenBlock();

                    setTimeout(() => {
                        block.recolorBlock(teethColorsIndex.white);
                    }, 1500);
                }
            }
        }
    }

    private cleanSpecificBoard = (
        rows: number[],
        columns: number[],
    ) => {
        const cleanSpaces: string[] = [];
        rows.forEach(row => {
            columns.forEach(column => {
                const blockID = row + '-' + column;

                cleanSpaces.push(blockID);
            });
        });

        for (let i = 0; i < BOARD_HEIGHT; i++) {
            for (let j = 0; j < BOARD_WIDTH; j++) {
                const block = this.board[i][j];
                if (!block) {
                    continue;
                }

                const blockID = i + '-' + j;

                if (cleanSpaces.includes(blockID)) {
                    block.clean();
                    this.board[i][j] = null;
                }
            }
        }
    }

    private setDominantClearingColor(
        colors: Record<number, number | undefined>,
    ) {
        let maximum = 0;
        let color: number | undefined;

        for (const [index, value] of Object.entries(colors)) {
            if (!value) {
                continue;
            }

            if (value > maximum) {
                maximum = value;
                color = parseInt(index);
            }
        }

        this.dominantClearingColor = color;
    }
    // #endregion clearing



    // #region scorings
    private increaseTeeth(
        value: number,
    ) {
        this.teethCount += value;

        this.publishTeeth();
    }

    private publishTeeth() {
        if (this.teethText) {
            this.teethText.setText('X' + this.teethCount);
        }
    }

    private increaseScore(
        coefficient: number,
        value: number,
    ) {
        this.playSound(audioNames.score);

        this.increaseTeeth(coefficient);

        const score = coefficient * value;
        this.score += score;
        this.collectScore += score;

        this.penguinCustomText = true;
        this.drawPenguinScoreText();

        this.penguinAnimateScore();

        this.publishScore();
    }

    private publishScore() {
        localStorer.setScore(this.score);

        if (this.scoreText) {
            this.scoreText.setText(
                displayScore(this.score),
            );
        }
    }

    private publishNextShape() {
        if (!this.nextShape) {
            return;
        }

        localStorer.setNextShape(
            JSON.stringify(this.nextShape.blocksData()),
        );
    }


    private increaseComboLevel(
        value: number,
    ) {
        this.comboLevel += value;

        const colorValue = this.dominantClearingColor ?? 0;
        const colorName = (colorsIndex as any)[colorValue] || colorsIndex[0];
        if (colorName) {
            this.comboText?.destroy();
            this.comboText = this.add.text(
                this.getCoordinate('x', 'comboText'),
                this.getCoordinate('y', 'comboText'),
                `X${this.comboLevel}`,
                {
                    ...messageRandomStyle,
                    color: colorName,
                    fontSize: this.fontSize,
                },
            );

            setTimeout(() => {
                this.comboText?.destroy();
            }, 3_000);
        }

        this.publishComboLevel();

        if (this.comboLevel >= COMBO_LEVEL_FOAM && !this.pasteGranted) {
            this.comboPaste();

            this.increaseShapeCount();
            this.publishNextShape();
            this.redrawGameFrameTop();

            this.pasteGranted = true;
        }

        this.debouncedResetComboLevel();
    }

    private resetComboLevel() {
        this.comboLevel = 0;

        this.publishComboLevel();
    }

    private publishComboLevel() {
        localStorer.setComboLevel(this.comboLevel);
    }
    // #endregion scorings



    // #region game lose
    private checkGameLost() {
        if (this.board[2][shapeCenters.x]) {
            this.gameLost = true;
            return true;
        }

        return false;
    }

    private publishGameLost() {
        localStorer.setGameLost(this.gameLost);
    }

    private handleGameLost() {
        if (this.handledGameLost) {
            return;
        }

        this.handledGameLost = true;

        this.drawPenguinFinishText();
        this.penguinAnimateFinish();
        this.drawFinishScreen();

        this.publishGameLost();

        this.addMobileInput();
    }
    // #endregion game lose



    // #region publishings
    private publishInitialGameState() {
        this.publishComboLevel();
        this.publishScore();
        this.publishGameLost();
    }

    private publishGamePaused() {
        localStorer.setGamePaused(this.gamePaused);
    }
    // #endregion publishings



    // #region sound
    public playSound(
        name: string,
        destructionTime: number = 5_000,
    ) {
        const soundsAllowed = localStorer.getSound();
        if (soundsAllowed) {
            const sfx = this.sound.add(name);
            sfx.play('', {
                volume: audioVolume,
            });

            setTimeout(() => {
                sfx.destroy();
            }, destructionTime);
        }
    }

    private playSpecialSound() {
        const soundsAllowed = localStorer.getSound();
        if (soundsAllowed) {
            this.specialSound = this.sound.add(audioNames.special);
            this.specialSound.play('', {
                loop: true,
                volume: audioVolume,
            });
        }
    }

    private stopSpecialSound() {
        this.specialSound?.destroy();
        this.specialSound = null;
    }

    private playEndgameSound() {
        const soundsAllowed = localStorer.getSound();
        if (soundsAllowed) {
            const event = new CustomEvent('teethrismute');
            window.dispatchEvent(event);

            const endgame = this.sound.add(audioNames.endgame);
            endgame.play('', {
                loop: true,
                volume: audioVolume,
            });
        }
    }
    // #endregion sound



    // #region finish screen actions
    private restartGame() {
        this.destroyPlayerNameText();

        const RESTART_TEXT_TIME = 1_000;

        this.restartingGame = true;

        this.add.rectangle(0, 0, 900, 900, 0x000000).setOrigin(0);

        this.penguin = this.add.sprite(
            this.getCoordinate('x', 'penguin-restart'),
            this.getCoordinate('y', 'penguin-restart'),
            spritesheetsNames.penguin,
            0,
        )
        .setOrigin(0);

        this.penguin.play(
            animations.penguin.jump,
        );


        let text = this.add.text(
            this.getCoordinate('x', 'penguin-restart-text'),
            this.getCoordinate('y', 'penguin-restart-text'),
            '3',
            {
                ...messageDefaultStyle,
                fontSize: this.fontSizeLarge,
            },
        );

        setTimeout(() => {
            text.setText('2');
            text.setStyle({
                color: colors.yellow,
            });
        }, RESTART_TEXT_TIME);

        setTimeout(() => {
            text.setText('1');
            text.setStyle({
                color: colors.red,
            });
        }, RESTART_TEXT_TIME * 2);

        setTimeout(() => {
            window.location.href = '/game';
        }, RESTART_TEXT_TIME * 3);
    }

    private quitGame() {
        window.location.href = '/';
    }
    // #endregion finish screen actions



    // #region keydown
    private handleKeyDown(event: any) {
        if (event.altKey && event.code === 'KeyQ') {
            this.toggleCleanMode();
            return;
        }

        if (this.viewingHighscore) {
            if (event.key === 'r') {
                this.restartGame();
                return;
            }

            if (event.key === 'q') {
                this.quitGame();
                return;
            }
        }


        if (!this.readingPlayerName) {
            return;
        }

        const textStyle = {
            ...messageRandomStyle,
            fontSize: this.fontSize,
        };


        if (event.code === 'Backspace') {
            if (this.playerName.length === 1) {
                if (this.nameText) {
                    this.nameText.setText('NAME');
                    this.nameText.setStyle({
                        color: '#ffffff',
                    });
                }
            }


            if (this.playerName.length === 0) {
                return;
            }

            const newPlayerName = this.playerName.slice(0, this.playerName.length - 1);
            this.playerName = newPlayerName;

            const playerNameDrawValue = displayNameField(newPlayerName);
            this.playerNameText?.destroy();
            this.playerNameText = this.add.text(
                this.getCoordinate('x', 'playerNameText'),
                this.getCoordinate('y', 'playerNameText'),
                playerNameDrawValue,
                textStyle,
            );

            return;
        }


        if (event.code === 'Enter') {
            if (this.playerName.length < 1) {
                return;
            }

            this.drawHighscore();
            return;
        }


        // Accept only characters.
        if (event.key.length > 1) {
            return;
        }

        const newPlayerName = this.playerName + event.key;
        if (newPlayerName.length > 8) {
            return;
        }

        this.playerName = newPlayerName;

        const playerNameDrawValue = displayNameField(newPlayerName);
        this.playerNameText?.destroy();
        this.playerNameText = this.add.text(
            this.getCoordinate('x', 'playerNameText'),
            this.getCoordinate('y', 'playerNameText'),
            playerNameDrawValue,
            textStyle,
        );


        if (this.playerName.length > 0) {
            if (this.nameText) {
                this.nameText.setText('NEXT');
                this.nameText.setStyle({
                    color: '#008ffd',
                });
            }
        }
    }
    // #endregion keydown



    // #region server requests
    private async queryHighscores() {
        try {
            const highscores = await getHighscores();

            this.highscores = highscores;
        } catch (error) {
            return;
        }
    }
    // #endregion server requests



    // #region varia
    private toggleCleanMode = () => {
        const show = () => {
            this.penguin?.setVisible(false);
            this.penguinText?.setVisible(false);

            this.teethText?.setVisible(false);
            this.scoreLiteralText?.setVisible(false);
            this.scoreText?.setVisible(false);

            this.assetsImage[imagesNames.logo]?.setVisible(false);

            this.assetsImage[imagesNames.scoreTooth]?.setVisible(false);
            this.assetsImage[imagesNames.toothpaste]?.setVisible(false);
            this.assetsImage[imagesNames.brush]?.setVisible(false);

            this.assetsImage['bubbles-0']?.setVisible(false);
            this.assetsImage['bubbles-1']?.setVisible(false);
            this.assetsImage['bubbles-2']?.setVisible(false);
            this.assetsImage['bubbles-3']?.setVisible(false);
            this.assetsImage['bubbles-4']?.setVisible(false);
            this.assetsImage['bubbles-5']?.setVisible(false);

            this.assetsRectangle['penguin-frame-background']?.setVisible(false);

            for (const sprite of this.penguinFrame) {
                sprite.setVisible(false);
            }
        }

        const hide = () => {
            this.penguin?.setVisible(true);
            this.penguinText?.setVisible(true);

            this.teethText?.setVisible(true);
            this.scoreLiteralText?.setVisible(true);
            this.scoreText?.setVisible(true);

            this.assetsImage[imagesNames.logo]?.setVisible(true);

            this.assetsImage[imagesNames.scoreTooth]?.setVisible(true);
            this.assetsImage[imagesNames.toothpaste]?.setVisible(true);
            this.assetsImage[imagesNames.brush]?.setVisible(true);

            this.assetsImage['bubbles-0']?.setVisible(true);
            this.assetsImage['bubbles-1']?.setVisible(true);
            this.assetsImage['bubbles-2']?.setVisible(true);
            this.assetsImage['bubbles-3']?.setVisible(true);
            this.assetsImage['bubbles-4']?.setVisible(true);
            this.assetsImage['bubbles-5']?.setVisible(true);

            this.assetsRectangle['penguin-frame-background']?.setVisible(true);

            for (const sprite of this.penguinFrame) {
                sprite.setVisible(true);
            }
        }


        if (this.cleanMode) {
            this.cleanMode = false;

            hide();
        } else {
            this.cleanMode = true;

            show();
        }
    }
    // #endregion varia
}
// #endregion module



// #region exports
export default Teethris;
// #endregion exports

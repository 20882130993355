// #region imports
    // #region external
    import background0 from '../../assets/images/backgrounds/background-0.png';
    import background1 from '../../assets/images/backgrounds/background-1.png';
    import background2 from '../../assets/images/backgrounds/background-2.png';
    import background3 from '../../assets/images/backgrounds/background-3.png';
    import background4 from '../../assets/images/backgrounds/background-4.png';
    import background5 from '../../assets/images/backgrounds/background-5.png';

    import logo0 from '../../assets/images/logo/logo-0.png';
    import logo1 from '../../assets/images/logo/logo-1.png';
    import logo2 from '../../assets/images/logo/logo-2.png';
    import logo3 from '../../assets/images/logo/logo-3.png';
    import logo4 from '../../assets/images/logo/logo-4.png';
    import logo5 from '../../assets/images/logo/logo-5.png';

    import toothbrush0 from '../../assets/images/story/toothbrush-0.png';
    import toothbrush1 from '../../assets/images/story/toothbrush-1.png';
    import toothbrush2 from '../../assets/images/story/toothbrush-2.png';

    import cursor from '../../assets/images/ui/cursor.png';

    import teeth from '../../assets/images/story/teeth.png';
    import cleaning from '../../assets/images/story/cleaning.png';

    import penguin from '../../assets/images/story/penguin.png';
    import penguinIdle from '../../assets/images/story/penguin-idle.png';

    import buttonStart from '../../assets/images/ui/button-start.png';
    import buttonStartClose from '../../assets/images/ui/button-start-close.png';
    import buttonStartOpen from '../../assets/images/ui/button-start-open.png';

    import checkedImage from '../../assets/images/ui/checked.png';
    import notCheckedImage from '../../assets/images/ui/not-checked.png';

    import select from '../../assets/images/ui/select.png';

    import paste from '../../assets/images/ui/paste.png';
    // #endregion external
// #endregion imports



// #region module
export {
    cursor,

    teeth,
    cleaning,

    penguin,
    penguinIdle,

    buttonStart,
    buttonStartClose,
    buttonStartOpen,

    checkedImage,
    notCheckedImage,

    select,

    paste,
};


export const backgrounds: Record<number, string> = {
    0: background0,
    1: background1,
    2: background2,
    3: background3,
    4: background4,
    5: background5,
};


export const logo: Record<number, string> = {
    0: logo0,
    1: logo1,
    2: logo2,
    3: logo3,
    4: logo4,
    5: logo5,
};


export const toothbrush: Record<number, any> = {
    0: toothbrush0,
    1: toothbrush1,
    2: toothbrush2,
};
// #endregion module

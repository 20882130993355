// #region imports
    // #region libraries
    import React from 'react';
    // #endregion libraries


    // #region external
    import Tooth from '../Tooth';
    // #endregion external


    // #region internal
    import {
        StyledSpinner,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface SpinnerProperties {
}

const Spinner: React.FC<SpinnerProperties> = (
    _properties,
) => {
    // #region render
    return (
        <StyledSpinner>
            <Tooth
                color={'white'}
            />
        </StyledSpinner>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Spinner;
// #endregion exports

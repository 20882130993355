// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        cursor,
    } from '../../data/images';

    import {
        colors,
        fonts,
    } from '../../data/constants';
    // #endregion external
// #region imports



// #region module
export const StyledButton = styled.button`
    background: none;
    border: none;
    outline: none;
    color: ${colors.grey};
    cursor: url(${cursor}) 16 16, pointer;
    font-family: ${fonts.Textfont};
    font-size: 2rem;
    user-select: none;

    :hover {
        color: ${colors.blue};
    }

    @media (max-width: 800px) {
        font-size: 1.5rem;

        :hover {
            color: ${colors.grey};
        }
    }
`;
// #endregion module

// #region module
export const scenario1 = [
    // [ 2, 0, 1 ],
    // [ 2, 2, 1 ],

    [ 0, 0, 1 ],
    [ 0, 0, 1 ],
    [ 0, 0, 1 ],
    [ 0, 0, 1 ],
    [ 1, 0, 0 ],
    [ 0, 1, 1 ],
    [ 1, 1, 0 ],
    [ 0, 0, 1 ],
    [ 1, 1, 0 ],
    [ 0, 0, 1 ],
    [ 0, 0, 1 ],
    [ 1, 1, 0 ],
    [ 0, 0, 1 ],
    [ 1, 1, 0 ],
    [ 0, 0, 1 ],
];
// #endregion module

// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';

    import {
        useNavigate,
    } from 'react-router-dom';
    // #endregion libraries


    // #region external
    import {
        backgrounds,
        select,
    } from '../../data/images';

    import {
        StyledButton,
    } from '../../components/styled';

    import Switch from '../../components/Switch';
    import SelectableItem from '../../components/SelectableItem';

    import {
        localStorer,
    } from '../../services/objects';
    // #endregion external


    // #region internal
    import {
        StyledOptions,
        StyledOption,
        StyledOptionBackgroundItem,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface OptionsProperties {
    backAction?: () => void;
}

const Options: React.FC<OptionsProperties> = (
    properties,
) => {
    // #region properties
    const {
        backAction,
    } = properties;
    // #endregion properties


    // #region state
    const [
        gameBackground,
        setGameBackground,
    ] = useState(0);

    const [
        gameMusic,
        setGameMusic,
    ] = useState(false);

    const [
        gameSound,
        setGameSound,
    ] = useState(false);

    const [
        selected,
        setSelected,
    ] = useState(3);
    // #endregion state


    // #region effects
    const navigate = useNavigate();

    useEffect(() => {
        const gameBackground = localStorer.getBackground();
        const gameMusic = localStorer.getMusic();
        const gameSound = localStorer.getSound();

        if (typeof gameBackground === 'number') {
            setGameBackground(gameBackground);
        }

        if (typeof gameMusic === 'boolean') {
            setGameMusic(gameMusic);
        }

        if (typeof gameSound === 'boolean') {
            setGameSound(gameSound);
        }
    }, []);

    useEffect(() => {
        localStorer.setBackground(gameBackground);
    }, [
        gameBackground,
    ]);

    useEffect(() => {
        localStorer.setMusic(gameMusic);
    }, [
        gameMusic,
    ]);

    useEffect(() => {
        localStorer.setSound(gameSound);
    }, [
        gameSound,
    ]);

    /** Arrow Binds */
    useEffect(() => {
        const arrowBinds = (
            event: KeyboardEvent,
        ) => {
            if (event.code === 'ArrowLeft') {
                if (selected !== 0) {
                    return;
                }

                const previousBackground = gameBackground - 1;
                if (previousBackground < 0) {
                    return;
                }

                setGameBackground(previousBackground);

                return;
            }

            if (event.code === 'ArrowRight') {
                if (selected !== 0) {
                    return;
                }

                const nextBackground = gameBackground + 1;
                if (nextBackground > 5) {
                    return;
                }

                setGameBackground(nextBackground);

                return;
            }

            if (event.code === 'ArrowUp') {
                if (selected === 0) {
                    return;
                }

                const previous = selected - 1;
                if (previous < 0) {
                    return;
                }

                setSelected(previous);
            }

            if (event.code === 'ArrowDown') {
                if (selected === 3) {
                    return;
                }

                const next = selected + 1;
                if (next > 3) {
                    return;
                }

                setSelected(next);

                return;
            }

            if (event.code === 'Enter' || event.code === 'Space') {
                switch (selected) {
                    case 0:
                        break;
                    case 1:
                        setGameMusic(active => !active);
                        break;
                    case 2:
                        setGameSound(active => !active);
                        break;
                    case 3:
                        if (backAction) {
                            backAction();
                            break;
                        }

                        navigate('/story');
                        break;
                }

                return;
            }
        }

        window.addEventListener('keydown', arrowBinds);

        return () => {
            window.removeEventListener('keydown', arrowBinds);
        }
    }, [
        selected,
        gameBackground,
        gameMusic,
        gameSound,
        navigate,
        backAction,
    ]);
    // #endregion effects


    // #region render
    return (
        <StyledOptions>
            <StyledOption
                onMouseEnter={() => {
                    setSelected(0);
                }}
            >
                <SelectableItem
                    text="Background:"
                    selected={selected === 0}
                />

                <div
                    style={{
                        display: 'flex',
                        gap: '2rem',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    {Object.entries(backgrounds).map(([key, value]) => {
                        const index = parseInt(key);
                        const selected = index === gameBackground;
                        const elementKey = `background-${index}`;

                        const overlay = selected ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '-9px',
                                    left: '-9px',
                                    pointerEvents: 'none',
                                }}
                            >
                                <img
                                    src={select}
                                    alt="selected"
                                    height={68}
                                    width={68}
                                />
                            </div>
                        ) : (
                            <></>
                        )

                        return (
                            <StyledOptionBackgroundItem
                                key={elementKey}
                                onClick={() => {
                                    setGameBackground(index);
                                }}
                            >
                                {overlay}

                                <img
                                    src={value}
                                    alt={elementKey}
                                    height={50}
                                    width={50}
                                />
                            </StyledOptionBackgroundItem>
                        );
                    })}
                </div>
            </StyledOption>


            <StyledOption
                onMouseEnter={() => {
                    setSelected(1);
                }}
            >
                <SelectableItem
                    text="Music:"
                    selected={selected === 1}
                />

                <Switch
                    checked={gameMusic}
                    atClick={() => {
                        setGameMusic(checked => !checked);
                    }}
                />
            </StyledOption>


            <StyledOption
                onMouseEnter={() => {
                    setSelected(2);
                }}
            >
                <SelectableItem
                    text="Sound:"
                    selected={selected === 2}
                />

                <Switch
                    checked={gameSound}
                    atClick={() => {
                        setGameSound(checked => !checked);
                    }}
                />
            </StyledOption>

            <div
                style={{
                    margin: '0 auto',
                }}
                onMouseEnter={() => {
                    setSelected(3);
                }}
            >
                <SelectableItem
                    text={(
                        <StyledButton
                            onClick={() => {
                                if (backAction) {
                                    backAction();
                                    return;
                                }

                                navigate('/story');
                            }}
                        >
                            SAVE
                        </StyledButton>
                    )}
                    selected={selected === 3}
                    iconStyle={{
                        top: '-10px',
                    }}
                />
            </div>
        </StyledOptions>
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Options;
// #endregion exports

// #region imports
    // #region libraries
    import styled from 'styled-components';
    // #region libraries


    // #region external
    import {
        colors,
    } from '../../data/constants/ui';
    // #endregion external
// #region imports



// #region module
export interface IStyledGamePaused {
}

export const StyledGamePaused = styled.div<IStyledGamePaused>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 9999;
    overflow: hidden;

    display: grid;
    justify-content: center;
    align-items: center;
    color: ${colors.grey};
`;


export const StyledPausedAssets = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    align-items: flex-end;
`;


export const StyledPausedText = styled.div`
    font-size: 2rem;
    text-align: center;
`;


export const StyledPausedButtons = styled.div`
    margin-bottom: 2rem;
    transform: translateX(calc(50% - 60px));

    @media (max-width: 800px) {
        transform: initial;
        text-align: center;
    }
`;


export const StyledPausedIcon = styled.div`
    pointer-events: none;

    @media (max-width: 800px) {
        transform: scale(0.7);
    }
`;


export const StyledText = styled.div`
    text-align: center;
    line-height: 2;

    @media (max-width: 800px) {
        font-size: 0.7rem;
    }
`;
// #region module

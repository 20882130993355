// #region imports
    // #region libraries
    import React, {
        useState,
        useEffect,
    } from 'react';
    // #endregion libraries


    // #region external
    import {
        paste,
    } from '../../data/images';
    // #endregion external


    // #region internal
    import {
        StyledPaste,
    } from './styled';
    // #endregion internal
// #region imports



// #region module
export interface PasteProperties {
}

const Paste: React.FC<PasteProperties> = (
    _properties,
) => {
    // #region properties
    const frames = [0, 1, 2, 3, 2, 1];
    // #endregion properties


    // #region state
    const [
        frame,
        setFrame,
    ] = useState(0);
    // #endregion state


    // #region effects
    useEffect(() => {
        const interval = setInterval(() => {
            setFrame(value => {
                if (value >= frames.length) {
                    return 0;
                }

                return value + 1;
            });
        }, 400);

        return () => {
            clearInterval(interval);
        }
    }, [
        frames.length,
    ]);
    // #endregion effects


    // #region render
    return (
        <StyledPaste
            style={{
                backgroundImage: `url(${paste})`,
                backgroundPosition: `${frames[frame] * 50 * -1}px 0px`,
            }}
        />
    );
    // #endregion render
}
// #endregion module



// #region exports
export default Paste;
// #endregion exports
